import 'swiper/css';

import { FreeMode, Mousewheel } from 'swiper/modules';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  RootState,
  selectOption,
  setName,
  setNewOptionsToAll,
  setOrderState,
  setWarm,
} from '../../redux/store';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { blockClass, blockRightStyle } from './styles';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../Button';
import { Checkbox } from '../Checkbox';
import { Modal } from '../Modal';
import { Radio } from '../Radio';
import { Selector } from '../Selector';
import Spinner from '../Spinner';
import clsx from 'clsx';
import { getPrice } from '../../utils/httpServices/global';
import { mockOptionsData } from './mockData';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { RQ_OPTS } from '../../utils/consts';

export interface Props {
  open?: boolean;
  dataKey: string | number;
  data: {
    index: number;
    indexAbs: number;
    isOptionsOpen: number;
    setIsOptionsOpen: (value: number) => void;
    typeKey: 'windows' | 'balconies';
    isLoading?: boolean;
  };
}

export const Cell = ({
  name,
  value,
  odd,
  className,
}: {
  name: string;
  value: string;
  odd: boolean;
  className?: string;
}) => (
  <div
    className={clsx(
      'flex items-center justify-between font-normal px-4 py-2 rounded-lg',
      !odd && 'bg-white',
      className,
    )}
  >
    {name && <p className="lg:text-base text-sm w-fit">{name}</p>}
    {value && <p className="lg:text-base text-sm text-end w-fit">{value}</p>}
  </div>
);

export const Item = ({
  hidePrice,
  data,
}: {
  hidePrice?: boolean;
  data: {
    name: string;
    image: string;
    unit_id: number;
    color_id: number;
    onClick: any;
    selected: boolean;
    price?: number;
  };
}) => {
  const { name, image, onClick, selected, price } = data;

  return (
    <div
      className={clsx(
        'flex items-center lg:justify-start justify-between lg:gap-2 gap-[6px] font-normal p-2 rounded-xl cursor-pointer',
        selected && 'bg-white',
      )}
      onClick={onClick}
    >
      <div
        className="min-w-[60px] aspect-square rounded-lg lg:mr-3 mr-1 bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${image})` }}
      />
      <p className="lg:text-base text-sm">{name}</p>
      {!hidePrice && (
        <p className="lg:text-base text-sm ml-auto whitespace-nowrap lg:pr-2">{price} ₽</p>
      )}
    </div>
  );
};

export const Options = ({ open, dataKey, data }: Props) => {
  const dispatch = useDispatch();
  const { isOptionsOpen, setIsOptionsOpen, index, indexAbs, typeKey, isLoading } = data;

  const order = useSelector((state: RootState) => state.global.order);
  const orderState = useSelector((state: RootState) => state.global.orderState);
  const options = useSelector((state: RootState) => state.global.options);
  const profilesInfo = useSelector((state: RootState) => state.global.profilesInfo);
  const roomData = order?.planData?.[indexAbs];
  const info = useSelector((state: RootState) => state.global.profilesInfo);
  const names = useSelector((state: RootState) => state.global.names);
  const prices = useSelector((state: RootState) => state.global.prices);
  const isWarm = orderState?.balconiesWarmGlass?.[index];

  const maxSize = '60vmin';
  const imgClass = `lg:w-2/3 w-full max-w-[${maxSize}] max-h-[${maxSize}] h-fit aspect-square rounded-2xl bg-cover bg-no-repeat ml-auto overflow-hidden`;
  const inBlockClass = `lg:w-1/2 w-full lg:aspect-square lg:!max-h-[${maxSize}] max-lg:max-h-[50dvh] overflow-auto`;

  const profiles = roomData?.systems;

  const profilesAvailable = useMemo(
    () =>
      profilesInfo?.systems.filter((el: any) =>
        profiles?.find((item: any) => item.name === el.name),
      ),
    [profiles, profilesInfo],
  );

  const [isDirty, setIsDirty] = useState(false);
  const [showSaveBtnSaved, setShowSaveBtnSaved] = useState(false);
  const [showCompare, setShowCompare] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);

  const [swiperMenu, setSwiperMenu] = useState<null | SwiperClass>(null);
  const onSwiper = (swiperInstance: any) => setSwiperMenu(swiperInstance);

  const [system, setSystem] = useState(orderState?.profiles?.[typeKey]?.[index] || 0);
  const [opening, setOpening] = useState(orderState?.systems?.[typeKey]?.[index] || 0);

  const constructions =
    order?.[typeKey === 'windows' ? 'windowsArr1' : 'balconiesArr1']?.[index]?.systems?.[system]
      ?.constructions;

  let openSys, openVars: any, openOptions: any;
  let openOptionsArr: any[] = [];
  if (constructions) {
    openVars = Object.values(constructions);
    openOptions = openVars?.[opening]?.options;
    openOptionsArr = openOptions ? Object.values(openOptions) : [];
    openSys = openVars?.[opening]?.options?.system?.values?.[0];
  }

  const sillIdx = useMemo(
    () => openOptionsArr.findIndex((el: any) => el?.slug === 'sill'),
    [openOptionsArr],
  );
  const handleIdx = useMemo(
    () => openOptionsArr.findIndex((el: any) => el?.slug === 'handle'),
    [openOptionsArr],
  );

  const laminationIdx = openOptionsArr.findIndex((el: any) => el?.slug === 'lamination');

  const [isCheckedToAll, setIsCheckedToAll] = useState(false);
  const [color, setColor] = useState(
    openOptions?.lamination?.values?.findIndex(
      (el: any) => el.name === names?.[typeKey]?.lamination_external?.[index],
    ),
  );

  const side1 = useMemo(
    () =>
      openOptions?.lamination?.values?.findIndex(
        (el: any) => el.name === names?.[typeKey]?.lamination_interior?.[index],
      ),
    [index, names, openOptions, typeKey],
  );
  const side2 = useMemo(
    () =>
      openOptions?.lamination?.values?.findIndex(
        (el: any) => el.name === names?.[typeKey]?.lamination_external?.[index],
      ),
    [index, names, openOptions, typeKey],
  );

  const [doubleSide, setDoubleSide] = useState({
    isDoubleSide:
      !!names?.[typeKey]?.lamination_external?.[index] &&
      !!names?.[typeKey]?.lamination_interior?.[index],
    isSame:
      names?.[typeKey]?.lamination_external?.[index] ===
      names?.[typeKey]?.lamination_interior?.[index],
    selectedInner: true,
    side1: side1 >= 0 ? side1 : color,
    side2: side2 >= 0 ? side2 : color,
  });

  const [profileData, setProfileData] = useState<any | null>(null);
  const [optData, setOptData] = useState<any>(null);

  const [sill, setSill] = useState(options?.[typeKey]?.[index]?.[sillIdx || 0]?.selected || 0);
  const [handle, setHandle] = useState(
    options?.[typeKey]?.[index]?.[handleIdx || 0]?.selected || 0,
  );

  const profileName = profiles?.[system]?.name;

  const systemsPrices = profiles?.map((el: any) => {
    const vals: any = Object.values(el.constructions);
    return {
      name: el.name,
      price: vals?.[0]?.options?.system?.values?.[0]?.price,
    };
  });

  const menuData = mockOptionsData
    .filter((el: any, i: number) => (typeKey === 'windows' && i > 4 ? null : el))
    .filter(Boolean);

  const { data: optPrice, isLoading: isLoading0 } = useQuery(
    ['options', JSON.stringify(optData)],
    () => (isOptionsOpen >= 0 && optData ? getPrice(optData) : null),
    RQ_OPTS,
  );

  const isLoading1 = isLoading || isLoading0;

  const select = useCallback(
    (i: number, selected: number) => {
      if (i < 0) return;
      dispatch(selectOption({ type: typeKey, index, i, selected }));
    },
    [dispatch, index, typeKey],
  );

  const handleOpeningChange = (i: number, newSystem: number) => {
    // const newData: any = JSON.parse(JSON.stringify(orderState));
    // let value = newData?.systems?.[typeKey]?.[index];
    // if (value !== undefined && typeof value === 'number') {
    //   newData.systems[typeKey][index] = i;
    //   dispatch(setOrderState(newData));
    //   handleSave(false);
    // }

    const constr =
      order?.[typeKey === 'windows' ? 'windowsArr1' : 'balconiesArr1']?.[index]?.systems?.[
        newSystem
      ]?.constructions;

    let openVars1: any, openOptions1: any;
    if (constr && typeof constr === 'object') {
      openVars1 = Object.values(constr);
      openOptions1 = openVars1?.[0]?.options;
    }

    if (!openOptions1?.sill?.values?.[sill]) setSill(0);
    if (!openOptions1?.handle?.values?.[handle]) setHandle(0);

    setIsDirty(true);
    setOpening(i);
  };

  const handleOptionsToAll = useCallback(() => {
    // const optionsNew: any = JSON.parse(JSON.stringify(options));

    // newData?.systems?.[typeKey]?.forEach((_: any, idx: number) => {
    // dispatch(selectOption({ type: typeKey, index: idx, i, selected }));
    // });
    names.balconies.lamination_interior.forEach((_, i) => {
      dispatch(
        setName({
          type: 'balconies',
          key: 'lamination_interior',
          index: i,
          value: doubleSide.isDoubleSide
            ? openOptions?.lamination?.values?.[doubleSide.isSame ? color : doubleSide.side1]?.name
            : '',
        }),
      );
    });

    names.windows.lamination_interior.forEach((_, i) => {
      dispatch(
        setName({
          type: 'windows',
          key: 'lamination_interior',
          index: i,
          value: doubleSide.isDoubleSide
            ? openOptions?.lamination?.values?.[doubleSide.isSame ? color : doubleSide.side1]?.name
            : '',
        }),
      );
    });

    names.balconies.lamination_external.forEach((_, i) => {
      dispatch(
        setName({
          type: 'balconies',
          key: 'lamination_external',
          index: i,
          value:
            openOptions?.lamination?.values?.[doubleSide.isSame ? color : doubleSide.side2]?.name ||
            '',
        }),
      );
    });

    names.windows.lamination_external.forEach((_, i) => {
      dispatch(
        setName({
          type: 'windows',
          key: 'lamination_external',
          index: i,
          value:
            openOptions?.lamination?.values?.[doubleSide.isSame ? color : doubleSide.side2]?.name ||
            '',
        }),
      );
    });

    const newOptions = {
      sill: openOptions?.sill?.values?.[sill]?.name || '',
      handle: openOptions?.handle?.values?.[handle]?.name || '',
    };

    dispatch(setNewOptionsToAll(newOptions));
  }, [color, dispatch, doubleSide, handle, names, openOptions, sill]);

  const handleToAll = useCallback(() => {
    const newData: any = JSON.parse(JSON.stringify(orderState));
    // let value = newData?.profiles?.[typeKey]?.[index];
    // if (value !== undefined && typeof value === 'number') {
    // newData.profiles[typeKey][index] = system;
    // newData.systems[typeKey][index] = opening;

    const arr = newData?.profiles?.[typeKey]?.map((_: any, i: number) => {
      if (
        order?.[typeKey === 'windows' ? 'windowsArr1' : 'balconiesArr1']?.[i]?.systems?.[system]
      ) {
        return system;
      }
      return 0;
    });
    newData.profiles[typeKey] = arr;
    newData.systems[typeKey] = arr.map(() => 0);
    newData.systems[typeKey][index] = opening;

    dispatch(setOrderState(newData));

    setTimeout(() => {
      handleOptionsToAll();
    }, 333);
  }, [dispatch, handleOptionsToAll, index, opening, order, orderState, system, typeKey]);

  const handleProfileChange = (i: number) => {
    if (isDirty) {
      if (!isToastVisible) {
        toast(
          'При изменении профиля будет сброшен выбранный тип открывания и возможно другие опции',
          { type: 'warning', autoClose: 9000 },
        );
        setIsToastVisible(true);
        setTimeout(() => {
          setIsToastVisible(false);
        }, 9000);
      }
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }

    handleOpeningChange(0, i);
    setSystem(i);
  };

  const handleSave = () => {
    if (options?.[typeKey]?.length) {
      if (isCheckedToAll) {
        handleToAll();
        setIsDirty(false);
        setShowSaveBtnSaved(true);
        setTimeout(() => {
          setShowSaveBtnSaved(false);
        }, 2500);
        // setIsOptionsOpen(-1);
        return;
      }

      const newData: any = JSON.parse(JSON.stringify(orderState));
      let values = newData?.profiles?.[typeKey];
      if (values.length) {
        newData.profiles[typeKey][index] = system;
        newData.systems[typeKey][index] = opening;
        dispatch(setOrderState(newData));
      }

      const selectedProfile = profiles?.[system];
      const isColdProfile = profilesInfo?.systems.find(
        (el: any) => el.id === selectedProfile.id,
      )?.is_cold;

      dispatch(setWarm({ index, value: !!!isColdProfile }));

      select(sillIdx, sill);
      select(handleIdx, handle);
      // select(extdoorsillIdx, extdoorsill);
      // select(extwindsillIdx, extwindsill);

      // if ((doubleSide.isSame && color >= 0) || !doubleSide.isSame) {
      dispatch(
        setName({
          type: typeKey,
          key: 'lamination_interior',
          index,
          value: doubleSide.isDoubleSide
            ? openOptions?.lamination?.values?.[doubleSide.isSame ? color : doubleSide.side1]?.name
            : '',
        }),
      );

      dispatch(
        setName({
          type: typeKey,
          key: 'lamination_external',
          index,
          value:
            openOptions?.lamination?.values?.[doubleSide.isSame ? color : doubleSide.side2]?.name ||
            '',
        }),
      );
      // }

      dispatch(
        setName({
          type: typeKey,
          key: 'sill',
          index,
          value: openOptions?.sill?.values?.[sill]?.name,
        }),
      );

      dispatch(
        setName({
          type: typeKey,
          key: 'handle',
          index,
          value: openOptions?.handle?.values?.[handle]?.name,
        }),
      );
    }

    //   select(sillIdx, isFinish ? sill : 0);
    //   select(handleIdx, isFinish ? handle : 0);

    //   if (isFinish) {
    //     setIsOptionsOpen(-1);
    //   } else {
    //     if (isFinish === null) {
    //       setOpening(0);
    //     } else {
    //       setSill(0);
    //       setHandle(0);
    //       setExtdoorsill(0);
    //       setExtwindsill(0);
    //     }
    //   }
    // }

    setIsDirty(false);
    setShowSaveBtnSaved(true);
    setTimeout(() => {
      setShowSaveBtnSaved(false);
    }, 2500);
  };

  useEffect(() => {
    if (!options) return;
    setSill(sillIdx >= 0 ? options?.[typeKey]?.[index]?.[sillIdx]?.selected : -1);
    setHandle(handleIdx >= 0 ? options?.[typeKey]?.[index]?.[handleIdx]?.selected : -1);
  }, [sillIdx, handleIdx, options, typeKey, index]);

  useEffect(() => {
    setDoubleSide((prev) => ({
      ...prev,
      side1: prev.side1 >= 0 ? prev.side1 : color,
      side2: prev.side2 >= 0 ? prev.side2 : color,
    }));
  }, [color]);

  useEffect(() => {
    const profile = profilesInfo?.systems?.find((el: any) => el.name === profileName);
    setProfileData(profile || null);
  }, [system]);

  useEffect(() => {
    if (names) {
      const lamColorIdx = openOptions?.lamination?.values?.findIndex(
        (el: any) => el.name === names?.[typeKey]?.lamination_external?.[index],
      );

      setColor(lamColorIdx);

      // setDoubleSide({
      //   isDoubleSide:
      //     !!names?.[typeKey]?.lamination_external?.[index] &&
      //     !!names?.[typeKey]?.lamination_interior?.[index],
      //   isSame:
      //     names?.[typeKey]?.lamination_external?.[index] ===
      //     names?.[typeKey]?.lamination_interior?.[index],
      //   selectedInner: true,
      //   side1: 0,
      //   side2: 0,
      // side1: openOptions?.lamination?.values?.findIndex(
      //   (el: any) => el.name === names?.[typeKey]?.lamination_interior?.[index],
      // ),
      // side2: openOptions?.lamination?.values?.findIndex(
      //   (el: any) => el.name === names?.[typeKey]?.lamination_external?.[index],
      // ),
      // });
    }
  }, [names]);

  useEffect(() => {
    if (showCompare && isOptionsOpen !== 0) {
      setIsOptionsOpen(0);
    }
  }, [showCompare]);

  useEffect(() => {
    if (typeof dataKey === 'number') {
      handleOpeningChange(dataKey, orderState?.profiles?.[typeKey]?.[index] || 0);
      return;
    }

    if (isWarm === undefined || !profiles || !profilesInfo) return;
    const selectedProfile = profiles?.[orderState.profiles.balconies?.[index]];
    const isColdProfile = profilesInfo?.systems.find(
      (el: any) => el.id === selectedProfile.id,
    )?.is_cold;

    if (isColdProfile === isWarm && profiles && profilesAvailable) {
      const newItem = profilesAvailable?.find((el: any) => el.is_cold !== isWarm);
      const newProfileIdx = profiles?.findIndex((el: any) => el?.id === newItem?.id);

      if (newProfileIdx >= 0) {
        setSystem(newProfileIdx);
        handleOpeningChange(0, newProfileIdx);

        setTimeout(() => {
          handleSave();
        }, 155);
      }
    }
  }, [dataKey]);

  const showMenuItem = useCallback(
    (option: any, i: number) => {
      const thisOptions = options?.[typeKey]?.[index];
      if (
        option.code === 'lamination' &&
        !names?.[typeKey]?.lamination_interior?.[index] &&
        !names?.[typeKey]?.lamination_external?.[index]
      )
        return null;

      if (
        (option.code === 'handle' || option.code === 'sill') &&
        (thisOptions?.find((e: any) => e.slug?.trim() === option.code)?.selected === undefined ||
          thisOptions?.find((e: any) => e.slug?.trim() === option.code)?.selected === -1)
      )
        return null;

      return (option.code && openOptions?.[option.code]?.values?.length > 0) || !option.code ? (
        <Button
          key={JSON.stringify(option) + i}
          variant={i === isOptionsOpen ? 'red' : 'white'}
          onClick={() => setIsOptionsOpen(i)}
          className={clsx('w-fit lg:!py-[10px] lg:!px-4', i === isOptionsOpen ? 'active' : '')}
        >
          {option.name}
        </Button>
      ) : null;
    },
    [index, isOptionsOpen, names, openOptions, options, setIsOptionsOpen, typeKey],
  );

  useEffect(() => {
    const openingData: any = constructions ? Object.values(constructions)[opening] : null;
    const activeOptions = options?.[typeKey]?.[index];
    const activeOptionsArr = activeOptions ? Object.values(activeOptions) : [];

    const lamOption = options?.[typeKey]?.[index]?.find((el: any) => el.slug === 'lamination');

    const initData = {
      id: roomData?.id,
      name: roomData?.name,
      item_id: roomData?.item_id,
      construction_id: openingData?.id,
      // profile: { id: profiles?.[system]?.id, name: profiles?.[system]?.name },
      // opening: { id: openingData?.id, name: openingData?.name },
      options: openingData?.options
        ? Object.values(openingData.options).map((option: any) => {
            if (activeOptions) {
              const optItems: any = activeOptionsArr.find((el: any) => el.name === option.name);

              let selected = optItems?.selected;
              if (optItems?.slug === 'handle' && selected >= 0) {
                selected = handle;
              }

              if (optItems?.slug === 'sill' && selected >= 0) {
                selected = sill;
              }

              let optItem: any =
                selected >= 0 ? { ...option?.values?.[selected] } : { ...option.values[0] };

              optItem.enabled = selected >= 0 && !!option?.values?.[selected];
              optItem.slug = option?.slug;

              delete optItem.image;
              delete optItem.price;
              delete optItem.color_id;
              delete optItem.unit_id;
              // delete optItem.name;

              if (optItems?.slug === 'lamination') {
                return {
                  enabled:
                    lamOption &&
                    lamOption?.selected >= 0 &&
                    !!profilesInfo?.lamination.values?.[doubleSide.side2]?.id,
                  id: profilesInfo?.lamination.values?.[doubleSide.side2]?.id,
                  name: profilesInfo?.lamination.values?.[doubleSide.side2]?.name,
                  slug: 'lamination_external',
                };
              }

              return optItem;
            }

            return [];
          })
        : null,
    };

    if (initData.options?.find((el) => el.slug === 'lamination_external')) {
      // const lamIntName = names?.[typeKey]?.lamination_interior?.[index] || '';
      // const lamIntId = profilesInfo?.lamination.values?.find(
      //   (el: any) => el.name === lamIntName,
      // )?.id;

      initData.options.push({
        enabled:
          lamOption &&
          lamOption?.selected >= 0 &&
          !!profilesInfo?.lamination.values?.[doubleSide.side1]?.id,
        id: profilesInfo?.lamination.values?.[doubleSide.side1]?.id,
        name: profilesInfo?.lamination.values?.[doubleSide.side1]?.name,
        slug: 'lamination_interior',
      });
    }

    setOptData(initData);
  }, [
    data,
    dispatch,
    order,
    system,
    opening,
    sill,
    handle,
    roomData,
    profiles,
    constructions,
    options,
    typeKey,
    index,
    names,
    profilesInfo,
    doubleSide,
    color,
  ]);

  useEffect(() => {
    if (isOptionsOpen >= 0 && isOptionsOpen < 5 && swiperMenu) {
      swiperMenu.slideTo(isOptionsOpen, 199);
    }
  }, [isOptionsOpen, swiperMenu]);

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOptionsOpen >= 0 && isOptionsOpen < 5) {
        setIsOptionsOpen(-1);
      }
    };

    document.addEventListener('keydown', handleEsc);
    return () => document.removeEventListener('keydown', handleEsc);
  }, []);

  if (!open) return null;

  return (
    <Modal
      className="relative !bg-[#F4F4F4] overflow-auto w-full lg:min-h-[85dvh] lg:w-[63%]"
      classNameModal="lg:items-center"
      onClose={() => {
        if (showCompare) {
          setShowCompare(false);
          setIsOptionsOpen(0);
        } else {
          setIsOptionsOpen(-1);
        }
      }}
    >
      {!showCompare && (
        <>
          <div className="flex lg:mb-2 lg:text-[23px]" id="options-label">
            Дополнительные опции
          </div>
          <div className="lg:hidden flex mt-4 mb-3">
            <Swiper
              slidesPerView={'auto'}
              className={'cursor-grab active:cursor-grabbing select-none'}
              onSwiper={onSwiper}
              modules={[FreeMode, Mousewheel]}
              mousewheel={{ forceToAxis: true }}
              freeMode={{ enabled: true, sticky: false }}
            >
              {menuData
                .filter((el: any, i: number) => (i < 5 ? el : null))
                .filter(Boolean)
                .map((option: any, i: number) =>
                  !!showMenuItem(option, i) ? (
                    <SwiperSlide
                      className={`!w-auto lg:mr-4 mr-3 last:mr-0`}
                      key={JSON.stringify(option) + i}
                    >
                      {showMenuItem(option, i)}
                    </SwiperSlide>
                  ) : null,
                )}
            </Swiper>
          </div>

          <div className="lg:flex hidden gap-3 mb-2">
            {menuData
              .filter((el: any, i: number) => (i < 5 ? el : null))
              .filter(Boolean)
              .map((option: any, i: number) => showMenuItem(option, i))}
          </div>
        </>
      )}
      {!showCompare && (
        <div
          className={'flex gap-2 items-center font-normal lg:text-base text-sm select-none mb-1'}
          onClick={() => setIsCheckedToAll(!isCheckedToAll)}
          role="button"
          tabIndex={0}
        >
          <Checkbox checked={isCheckedToAll} onChange={() => {}} rounded /> Применить ко всем окнам
        </div>
      )}

      {isOptionsOpen === 0 && showCompare && (
        <div className="flex mt-6">
          <div className="flex flex-col mt-[46px] w-fit mr-3 mt-[62px]">
            {profileData?.info?.properties
              ? Object.entries(profileData?.info?.properties).map(([name, value], i) => (
                  <Cell
                    key={name + value}
                    odd={i % 2 !== 0}
                    name={name}
                    value={''}
                    className="whitespace-nowrap"
                  />
                ))
              : null}
          </div>
          <div
            className="w-[2px] min-h-full"
            style={{ boxShadow: `6px 0px 6px 0px rgba(0,0,0,0.44)` }}
          />

          <Swiper
            slidesPerView={'auto'}
            className={'cursor-grab active:cursor-grabbing select-none mt-4 !mr-0 !ml-[7px]'}
            modules={[FreeMode, Mousewheel]}
            mousewheel={{ forceToAxis: true }}
            freeMode={{ enabled: true, sticky: false }}
          >
            {profiles?.map((item: any, idx: number) => {
              const dataInfo = profilesInfo?.systems?.find((el: any) => el.id === item.id);
              const info = dataInfo?.info?.properties;

              return (
                <SwiperSlide className={`!w-auto mr-3 last:mr-0`} key={item?.id || idx}>
                  <div
                    className={clsx(
                      idx === system ? 'border-[#FF0707]' : 'border-[transparent]',
                      'p-3 pt-1 rounded-[12px] cursor-pointer border-2 transition-all',
                    )}
                    onClick={() => {
                      handleProfileChange(idx);
                    }}
                  >
                    <div className="font-Istok text-base mb-4">{item.name}</div>
                    {info &&
                      Object.values(info).map((v: any, i: number) => (
                        <Cell
                          key={i}
                          odd={i % 2 !== 0}
                          name={''}
                          value={v || '?'}
                          className="whitespace-nowrap"
                        />
                      ))}
                    <div className="rounded-[8px] bg-[#FDDBDB] text-[#FF0707] font-Istok text-base font-bold px-4 py-2 mt-1">
                      {isLoading1 ? (
                        <Spinner />
                      ) : (
                        systemsPrices?.find((el: any) => el.name === item?.name)?.price
                      )}
                      &nbsp;₽
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}

      {isOptionsOpen === 0 && !showCompare && (
        <>
          <div className="bg-[#EAEAEA] rounded-[20px] lg:p-4 p-2 pr-3 flex lg:items-center items-start">
            <div className="flex lg:flex-row flex-col lg:gap-10 gap-2 lg:items-center">
              {profiles?.map((option: any, i: number) => (
                <div
                  key={JSON.stringify(option)}
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => handleProfileChange(i)}
                >
                  <Radio checked={i === system} />{' '}
                  <p className={clsx('font-Istok text-base', system === i && 'text-[#FF4545]')}>
                    {option.name}
                  </p>
                </div>
              ))}
            </div>
            <button
              className="text-[#FF4545] font-normal text-sm ml-auto"
              onClick={() => setShowCompare((prev) => !prev)}
            >
              Сравнить профили
            </button>
          </div>
          <div className="bg-[#EAEAEA] rounded-[20px] p-4 flex lg:flex-row flex-col justify-between mt-4">
            <div className="lg:w-1/2 w-full flex flex-col">
              <div
                className={clsx(
                  'aspect-square rounded-2xl bg-center bg-no-repeat w-full lg:block hidden',
                  profileData?.description?.length > 250 ? 'lg:w-1/4' : 'lg:w-1/3',
                )}
                style={{ backgroundImage: `url(${profiles?.[system]?.image_path})` }}
              />
              <div
                className="aspect-square rounded-2xl bg-black bg-contain bg-center bg-no-repeat w-full lg:hidden block"
                style={{
                  backgroundImage: `url(${profiles?.[system]?.image_path})`,
                  backgroundSize: 'auto 85%',
                  aspectRatio: 1.6,
                }}
              />
              <p className="font-Istok lg:text-base text-sm mt-4 mb-1">
                {profiles?.[system]?.name}
              </p>
              <p
                className={clsx(
                  'font-normal',
                  profileData?.description?.length > 250 ? 'text-[13px]' : 'lg:text-[15px] text-sm',
                )}
                dangerouslySetInnerHTML={{
                  __html:
                    profileData?.description ||
                    profileData?.info?.description ||
                    profiles?.[system]?.description,
                }}
              />
              <p className="font-Istok lg:text-[22px] text-[19px] mt-4 lg:mb-0 mb-4">
                {openSys?.price} ₽
              </p>
            </div>
            <div className={clsx('lg:w-[40%] w-full overflow-auto lg:!max-h-[50vh]')}>
              {profileData?.info?.properties
                ? Object.entries(profileData.info.properties).map(([name, value], i) => (
                    <Cell
                      key={name + value}
                      odd={i % 2 !== 0}
                      name={name}
                      value={value as string}
                    />
                  ))
                : null}
            </div>
          </div>
        </>
      )}
      {isOptionsOpen === 1 && (
        <div className={clsx('bg-[#EAEAEA] rounded-[20px] p-4 flex items-center')}>
          <Swiper
            slidesPerView={'auto'}
            className={'cursor-grab active:cursor-grabbing select-none'}
            modules={[FreeMode, Mousewheel]}
            mousewheel={{ forceToAxis: true }}
            freeMode={{ enabled: true, sticky: false }}
          >
            {(constructions ? Object.values(constructions) : [])?.map((el: any, i: number) => (
              <SwiperSlide className={`!w-auto mr-4 last:mr-0`} key={JSON.stringify(el)}>
                <div
                  className={clsx(
                    'lg:w-[320px] w-[180px] aspect-square rounded-3xl relative border-2',
                    i === opening ? 'border-[#FF4545]' : 'border-[transparent]',
                  )}
                  style={{
                    background: `url(${el?.image_path}) no-repeat center`,
                    backgroundSize: 'cover',
                  }}
                  onClick={() => handleOpeningChange(i, system)}
                >
                  <Radio
                    checked={opening === i}
                    onChange={() => {}}
                    className="absolute top-3 right-3"
                  />
                </div>
                <p className="mt-4 mb-2 font-Istok flex flex-wrap lg:w-[320px] w-[180px] ">
                  {el.name}
                </p>
                <p className="text-[15px] font-normal">
                  {el?.options?.system?.values?.[0]?.price} ₽
                </p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {isOptionsOpen === 2 && laminationIdx >= 0 && (
        <>
          <div className="bg-[#EAEAEA] rounded-[20px] p-4 flex flex-col gap-4 mb-4">
            <div
              className={clsx(
                'flex gap-3 items-center font-Istok transition-all lg:text-base text-sm',
                doubleSide.isDoubleSide && 'text-[#FF4545]',
              )}
            >
              <Selector
                hideText
                checked={doubleSide.isDoubleSide}
                onChange={() => {
                  setIsDirty(true);
                  setDoubleSide((p) => ({ ...p, isDoubleSide: !p.isDoubleSide }));
                }}
              />
              Двусторонняя ламинация
            </div>
            {doubleSide.isDoubleSide && (
              <div className="flex gap-6 cursor-pointer lg:text-base text-sm">
                <div
                  className="flex gap-2"
                  onClick={() => {
                    setIsDirty(true);
                    setDoubleSide((p) => ({ ...p, isSame: true }));
                  }}
                >
                  <Radio checked={doubleSide.isSame} />
                  <p className={clsx(doubleSide.isSame && 'text-[#FF4545]')}>
                    Одинаковая с двух сторон
                  </p>
                </div>
                <div
                  className="flex gap-2"
                  onClick={() => {
                    setIsDirty(true);
                    setDoubleSide((p) => ({ ...p, isSame: false }));
                  }}
                >
                  <Radio checked={!doubleSide.isSame} />
                  <p className={clsx(!doubleSide.isSame && 'text-[#FF4545]')}>
                    Разные расцветки внутри и снаружи
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className={clsx(
              blockClass,
              'lg:aspect-[3.1]',
              doubleSide.isDoubleSide && !doubleSide.isSame && 'lg:flex-row-reverse',
            )}
          >
            <div className="lg:w-1/2 w-full overflow-auto h-full flex flex-col max-lg:max-h-[50dvh]">
              {openOptions?.lamination?.values?.map((el: any, i: number) => (
                <Item
                  hidePrice
                  data={{
                    ...el,
                    onClick: () => {
                      setIsDirty(true);
                      if (doubleSide.isDoubleSide && !doubleSide.isSame) {
                        if (doubleSide.selectedInner) {
                          setDoubleSide((p) => ({ ...p, side1: i }));
                        } else {
                          setDoubleSide((p) => ({ ...p, side2: i }));
                        }
                      } else {
                        setColor(i);
                      }
                    },
                    selected:
                      doubleSide.isDoubleSide && !doubleSide.isSame
                        ? doubleSide.selectedInner
                          ? doubleSide.side1 === i
                          : doubleSide.side2 === i
                        : color === i,
                  }}
                  key={JSON.stringify(el)}
                />
              ))}
            </div>
            {doubleSide.isDoubleSide && !doubleSide.isSame ? (
              <div className="lg:w-1/2 lg:h-full h-fit w-full flex flex-col gap-2">
                <div
                  className="w-full aspect-[2/1] h-fit rounded-2xl transition-all cursor-pointer relative bg-no-repeat bg-cover relative"
                  style={{
                    backgroundImage: `url(${info?.lamination?.values?.[
                      doubleSide.side1
                    ]?.image_path?.replace('ruimage', 'ru/image')})`,
                    border: `3px solid ${doubleSide.selectedInner ? '#FF4545' : 'rgba(0,0,0,.05)'}`,
                  }}
                  onClick={() => {
                    setIsDirty(true);
                    setDoubleSide((p) => ({ ...p, selectedInner: true }));
                  }}
                >
                  <Button
                    variant={doubleSide.selectedInner ? 'red' : 'white'}
                    className="absolute bottom-3 left-3"
                  >
                    Внутри помещения
                  </Button>
                  {!info?.lamination?.values?.[doubleSide.side1]?.image_path && (
                    <div className="absolute lg:top-1/2 top-1/3 left-1/2 -translate-x-1/2 lg:-translate-y-1/2">
                      Цвет не выбран
                    </div>
                  )}
                </div>
                <div
                  className="w-full aspect-[2/1] h-fit rounded-2xl border transition-all cursor-pointer relative bg-no-repeat bg-cover relative"
                  style={{
                    backgroundImage: `url(${info?.lamination?.values?.[
                      doubleSide.side2
                    ]?.image_path?.replace('ruimage', 'ru/image')})`,
                    border: `3px solid ${
                      !doubleSide.selectedInner ? '#FF4545' : 'rgba(0,0,0,.05)'
                    }`,
                  }}
                  onClick={() => {
                    setIsDirty(true);
                    setDoubleSide((p) => ({ ...p, selectedInner: false }));
                  }}
                >
                  <Button
                    variant={!doubleSide.selectedInner ? 'red' : 'white'}
                    className="absolute bottom-3 left-3"
                  >
                    Со стороны улицы
                  </Button>
                  {!info?.lamination?.values?.[doubleSide.side2]?.image_path && (
                    <div className="absolute lg:top-1/2 top-1/3 left-1/2 -translate-x-1/2 lg:-translate-y-1/2">
                      Цвет не выбран
                    </div>
                  )}
                </div>
              </div>
            ) : color >= 0 ? (
              <div className={blockRightStyle}>
                <div
                  className={imgClass}
                  style={{
                    backgroundImage: `url(${info?.lamination?.values?.[color]?.image_path})`,
                  }}
                />
              </div>
            ) : null}
          </div>
        </>
      )}
      {isOptionsOpen === 3 &&
        openOptions?.sill &&
        sillIdx >= 0 &&
        openOptions?.sill?.values?.length > 0 && (
          <div className={blockClass}>
            <div className={inBlockClass}>
              {openOptions?.sill?.values?.map((el: any, i: number) => (
                <Item
                  data={{
                    ...el,
                    onClick: () => {
                      setIsDirty(true);
                      setSill(i);
                    },
                    selected: sill === i,
                  }}
                  key={JSON.stringify(el)}
                />
              ))}
            </div>

            <div
              className={clsx(blockRightStyle, imgClass)}
              style={{ backgroundImage: `url(${openOptions?.sill.values?.[sill]?.image})` }}
            >
              {/* <div */}
              {/* className={imgClass} */}
              {/* /> */}
            </div>
          </div>
        )}
      {isOptionsOpen === 4 && handleIdx >= 0 && openOptions?.handle?.values?.length > 0 && (
        <div>
          <div className={blockClass}>
            <div className={inBlockClass}>
              {/* {resVal?.options?.handle?.values?.map((el: any, i: number) => ( */}
              {openOptions?.handle?.values?.map((el: any, i: number) => (
                <Item
                  data={{
                    ...el,
                    onClick: () => {
                      setIsDirty(true);
                      setHandle(i);
                    },
                    selected: handle === i,
                  }}
                  key={JSON.stringify(el)}
                />
              ))}
            </div>

            <div
              className={clsx(blockRightStyle, imgClass, typeKey === 'balconies' && 'min-h-[40vh]')}
              style={{ backgroundImage: `url(${openOptions?.handle?.values?.[handle]?.image})` }}
            />
          </div>
        </div>
      )}

      <div
        className="bg-white rounded-[20px] p-4 flex justify-between mt-3"
        style={{
          alignSelf: 'flex-end',
          justifySelf: 'flex-end',
          boxShadow:
            '0px 8px 12.7px 0px rgba(0, 0, 0, 0.19), 0px -3px 12.7px 0px rgba(0, 0, 0, 0.19)',
        }}
      >
        <div className="lg:block flex flex-col justify-between">
          <p className="font-Istok lg:text-[22px] text-[19px] lg:mb-1">Цена окна</p>
          <p className="text-base font-normal leading-none flex flex-wrap">
            с учетом
            <br className="lg:hidden block" /> выбранных опций
          </p>
        </div>

        <div>
          <div
            className="mb-3 text-[#FF4545] font-Istok lg:text-[22px] text-[19px] w-fit ml-auto transition-all flex items-center"
            // style={{ opacity: isDirty ? 0 : 1 }}
          >
            {isLoading1 ? (
              <Spinner />
            ) : (
              <>
                {prices?.rooms?.[roomData?.item_id] && !isDirty
                  ? prices?.rooms?.[roomData?.item_id]?.total
                  : optPrice?.total || prices?.rooms?.[roomData?.item_id]?.total}
              </>
            )}
            {!isLoading1 && ` ₽`}
          </div>
          <div className="flex gap-2">
            <Button
              variant="white"
              onClick={() => setIsOptionsOpen(-1)}
              className="lg:!block !hidden"
            >
              Закрыть
            </Button>
            <Button
              variant={showSaveBtnSaved ? 'grey' : 'red'}
              onClick={() => handleSave()}
              disabled={!isDirty}
            >
              {showSaveBtnSaved ? 'Изменения сохранены!' : 'Сохранить'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
