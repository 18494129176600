import { useEffect } from 'react';

const useCalltouch = () => {
  useEffect(() => {
    (function (w, d, n, c) {
      w.CalltouchDataObject = n;
      w[n] = function () {
        w[n]['callbacks'].push(arguments);
      };
      if (!w[n]['callbacks']) {
        w[n]['callbacks'] = [];
      }
      w[n]['loaded'] = false;
      if (typeof c !== 'object') {
        c = [c];
      }
      w[n]['counters'] = c;
      for (var i = 0; i < c.length; i += 1) {
        p(c[i]);
      }
      function p(cId) {
        console.log('Calltouch');
        var a = d.getElementsByTagName('script')[0],
          s = d.createElement('script'),
          i = function () {
            a.parentNode.insertBefore(s, a);
          },
          m = typeof Array.prototype.find === 'function',
          n = m ? 'init-min.js' : 'init.js';
        s.async = true;
        s.src = 'https://mod.calltouch.ru/' + n + '?id=' + cId;
        if (w.opera === '[object Opera]') {
          d.addEventListener('DOMContentLoaded', i, false);
        } else {
          i();
        }
      }
    })(window, document, 'ct', '3393b1b1');
  }, []);
};

export default useCalltouch;
