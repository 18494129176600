import React, { useEffect, useRef } from 'react';

import clsx from 'clsx';
import { createPortal } from 'react-dom';

interface Props {
  children?: React.ReactNode;
  className?: string;
  classNameModal?: string;
  onClose?: () => void;
  hideCloseButton?: boolean;
}

export const Modal = ({
  children,
  className,
  classNameModal,
  onClose,
  hideCloseButton = false,
}: Props) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const rootEl = document.getElementById('root');

  const closeModal = () => {
    document.body.style.overflow = 'auto';

    if (onClose) onClose();
  };

  const handleClickOutside = (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement;
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      const tagName = targetElement?.tagName?.toLowerCase();
      const tagId = targetElement?.id;
      const parent = targetElement.closest('.Toastify__toast-body');

      if (
        (!parent && tagName !== 'button' && tagName !== 'svg' && tagName !== 'path') ||
        tagId === 'modalbg'
      ) {
        closeModal();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    if (modalRef?.current) {
      modalRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return rootEl
    ? createPortal(
        <div
          id="modalbg"
          className={clsx(
            'fixed top-0 left-0 right-0 bottom-0 flex justify-center z-50 overflow-auto z-[255]',
            classNameModal || 'items-center',
          )}
          style={{ background: 'rgba(32, 32, 32, 0.45)' }}
        >
          <div
            ref={modalRef}
            className={clsx(
              'bg-white lg:p-5 lg:pb-3 lg:pt-4 p-4 rounded-2xl relative h-fit lg:max-h-[99dvh] overflow-auto',
              className,
            )}
          >
            {!hideCloseButton && (
              <button onClick={closeModal} className="absolute lg:top-6 lg:right-6 top-4 right-4">
                ✕
              </button>
            )}
            {children}
          </div>
        </div>,
        rootEl,
      )
    : null;
};
