import { extUrl } from '../../utils/consts';

export const menuItems = [
  {
    name: 'О компании',
    path: `${extUrl}/o-kompanii`,
  },
  {
    name: 'Оплата заказа',
    path: `${extUrl}/oplata`,
  },
  {
    name: 'Доставка',
    path: `${extUrl}/uslugi/dostavka`,
  },
  {
    name: 'Личный кабинет',
    path: `${extUrl}/dogovor-oferty`,
  },
  {
    name: 'Контакты',
    path: `${extUrl}/kontakty`,
  },
];
