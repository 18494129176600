import clsx from 'clsx';
import React, { ForwardedRef, forwardRef } from 'react';
import Button from '../Button';
import { CrossIcon } from '../Icons/Cross';
import { MIN_LENGTH } from '../../utils/consts';
import Spinner from '../Spinner';
import css from './index.module.css';

interface Props {
  className?: string;
  onFinish?: () => void;
  options: { label: string | number; value: string | number }[] | null;
  value: any;
  onChange: any;
  selected: any;
  setSelected: any;
  setValue: any;
  isLoading?: boolean;
}

const Dropdown = (
  {
    className,
    onFinish,
    options,
    value,
    onChange,
    selected,
    setSelected,
    setValue,
    isLoading = false,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const Btn = ({ className }: { className: string }) => (
    <Button
      variant="red"
      className={className}
      onClick={() => onFinish && onFinish()}
      onKeyDown={(e) => {
        if ((e.key === 'Enter' || e.key === ' ') && onFinish) onFinish();
      }}
      disabled={!selected?.data?.is_final}
    >
      Рассчитать
    </Button>
  );

  return (
    <div className="relative w-full">
      <div className="relative w-full">
        <input
          className={clsx(
            'bg-white outline-none border-2 border-[transparent] focus:border-[#3E3E40] rounded-2xl overflow-hidden',
            'py-[6px] px-4 lg:pr-[180px] max-lg:pr-8',
            className,
          )}
          style={{ fontSize: 18 }}
          type="text"
          placeholder="Братиславская, 8"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <div className="flex items-center absolute top-1/2 -translate-y-1/2 right-2 gap-2">
          {value ? (
            isLoading ? (
              <div className="select-none">
                <Spinner />
              </div>
            ) : (
              <CrossIcon
                role="button"
                tabIndex={0}
                aria-label="Очистить поле ввода"
                className="cursor-pointer select-none"
                onClick={() => onChange('')}
              />
            )
          ) : null}

          <Btn className="md:text-[18px] text-sm font-Istok select-none max-lg:hidden" />
        </div>
      </div>
      <Btn className="md:text-[18px] text-sm font-Istok select-none absolute top-20 lg:hidden w-full" />

      {!isLoading && options && value.length > MIN_LENGTH - 1 ? (
        <div className="mt-2 p-4 pr-[6px] bg-white rounded-2xl absolute w-full">
          {options.length ? (
            <div className={clsx('flex flex-col gap-4 overflow-auto max-h-[276px]', css.scroll)}>
              {options.map((el) => (
                <div
                  role="button"
                  tabIndex={0}
                  key={JSON.stringify(el)}
                  className="text-base font-normal transition-all hover:font-semibold hover:text-[18px] cursor-pointer"
                  onClick={() => {
                    setValue(el.label);
                    setSelected(el);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setValue(el.label);
                      setSelected(el);
                    }
                  }}
                >
                  {el.label}
                </div>
              ))}
            </div>
          ) : (
            <p className="text-base font-normal font-Istok text-center py-[36px]">
              По вашему запросу адреса не найдены
            </p>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default forwardRef(Dropdown);
