import React from 'react';

export interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const RehauQR = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
    <g clipPath="url(#clip0_2860_52922)">
      <path
        d="M57.9507 52.4503H68.9552C69.0015 53.3957 69.0417 54.2164 69.0856 55.1135H71.7494V52.5517H74.5329V63.5035L74.5662 63.4727C73.6355 63.4843 72.7048 63.496 71.774 63.5077C71.7739 62.6149 71.7739 61.7221 71.7739 60.8252H69.1174V63.4756H71.8062V66.1747C70.7358 66.2422 69.9165 66.2938 68.9861 66.3525V68.952C68.0501 68.9967 67.2299 69.0359 66.3417 69.0783V71.7866H71.7197C71.767 72.7611 71.8068 73.5818 71.8542 74.5599H74.5662L74.5329 74.5291V77.2421C73.5848 77.288 72.7638 77.3278 71.8498 77.372C71.8061 78.2796 71.7665 79.1003 71.7235 79.9928H63.5115V77.293L63.4782 77.3238H66.1616V74.5284H63.4782L63.5097 74.5599V69.1053H49.6575V71.7959L49.6883 71.7626H46.9899C46.938 72.7129 46.8936 73.5256 46.8389 74.5265H44.13L44.1608 74.5599C44.1491 71.7866 44.1374 69.0133 44.1258 66.24C45.0186 66.24 45.9115 66.24 46.8085 66.24V63.5836H44.1578V66.2722H41.3461V63.5704H38.6795C38.6365 64.4634 38.597 65.2851 38.5546 66.1659H35.8684V60.7088L35.8369 60.7402H41.3967V55.1809L41.3652 55.2124H44.0745C44.1295 56.2156 44.1742 57.0291 44.2245 57.9455H46.9267V60.6036H55.2407V55.262C56.1787 55.2165 57.0002 55.1766 57.9814 55.129V52.417L57.9507 52.4503ZM66.1788 58.0812H58.0863V66.1318H66.1788V58.0812ZM49.7913 63.5656V66.1344H52.3194V63.5656H49.7913Z"
        fill="white"
      />
      <path
        d="M27.5483 5.4667H35.7832C35.8313 4.48752 35.8717 3.66748 35.917 2.7472C36.8165 2.70371 37.6368 2.66405 38.5539 2.61971C38.5973 1.7157 38.6366 0.895451 38.6795 0.000976562H41.3453V2.56483H44.0563C44.1052 1.78556 44.1567 0.965511 44.2153 0.0334519H55.1842V2.69849L55.2175 2.66771H47.0261V8.09156H49.6227C49.6625 7.18305 49.6971 6.3922 49.7348 5.53037H52.3747C52.4182 6.42713 52.4579 7.24767 52.5054 8.22705H55.2175L55.1842 8.19627V13.7542L55.2175 13.7234H52.4219V19.2827L52.4534 19.2512H49.7439C49.6892 18.2488 49.6448 17.436 49.5948 16.5206C48.62 16.5206 47.7572 16.5206 46.8944 16.5206C46.8944 15.6217 46.8944 14.7227 46.8944 13.815C45.8596 13.7495 45.0398 13.6976 44.1971 13.6443C44.1563 12.7112 44.1217 11.9196 44.0841 11.059H41.4669V19.2519H44.1628V21.9604C45.1647 22.0154 45.9776 22.0601 46.9244 22.1121V24.8124C45.0714 24.8013 43.2184 24.7902 41.3654 24.7791L41.3969 24.8106V22.1274H38.6013V24.8106L38.6328 24.7791H33.1045V22.0152L33.073 22.0467H35.8353C35.8353 20.9717 35.8353 20.1118 35.8353 19.252C36.7263 19.2519 37.6173 19.2519 38.5203 19.2519V16.5181H35.8353V13.7043H38.5348V11.0606H33.1063C33.1063 12.9344 33.1063 14.7262 33.1063 16.5181C32.1738 16.5078 31.2414 16.4976 30.3089 16.4873L30.3422 16.5181V11.0619H24.859C24.8164 11.9413 24.7767 12.7616 24.7334 13.6571H22.1123V8.28654H27.5804V5.43464L27.5483 5.4667Z"
        fill="white"
      />
      <path
        d="M52.4245 38.5938H55.1299C55.1778 39.5663 55.218 40.3835 55.2636 41.3079C56.1495 41.3525 56.9668 41.3936 57.9505 41.4431C57.9505 45.1569 57.9505 48.8039 57.9505 52.4509C57.9505 52.4509 57.9813 52.4175 57.9813 52.4175H55.1536V44.2316H49.6907C49.6907 45.1854 49.6907 46.0529 49.6907 46.9205C48.8025 46.9205 47.9143 46.9205 46.9241 46.9205V49.6869C45.9936 49.6875 45.0631 49.6881 44.1326 49.6886C44.1326 48.7958 44.1326 47.9031 44.1326 47.0061H41.476V49.6566H44.1648V52.3609C43.1252 52.4179 42.3126 52.4624 41.365 52.5144V55.2129L41.3965 55.1815H38.69C38.6312 56.2217 38.5852 57.0359 38.5366 57.8953C37.6054 57.9449 36.7911 57.9883 35.8368 58.0391V60.7408L35.8683 60.7093H33.0727L33.1042 60.7408V57.9265H30.4062V55.2603C31.3018 55.2169 32.1227 55.1772 33.0708 55.1313C33.0708 54.1602 33.0708 53.2892 33.0708 52.4182C33.9591 52.4182 34.8473 52.4182 35.8375 52.4182V49.6518H38.6901V44.106H41.2984V41.3727H35.9193C35.8719 40.3959 35.832 39.5756 35.786 38.6287H33.0708C33.0819 37.6971 33.0931 36.7656 33.1042 35.834L33.0727 35.8655H35.7561V33.0701H33.0727L33.1042 33.1016V27.5254H38.6324V24.7783L38.6009 24.8098H41.3965L41.365 24.7783V27.4859C42.3991 27.5423 43.2119 27.5867 44.1625 27.6386C44.1625 28.6114 44.1625 29.4742 44.1625 30.3369C43.2714 30.3369 42.3803 30.3369 41.4001 30.3369C41.4001 32.3085 41.4001 34.0852 41.4001 35.862C40.5072 35.8621 39.6143 35.8621 38.7174 35.8621V38.5185H41.368V35.8299C45.063 35.8418 48.758 35.8536 52.453 35.8655C52.453 35.8655 52.4197 35.8347 52.4197 35.8347C52.432 36.7651 52.4443 37.6955 52.4566 38.626C51.5637 38.626 50.6709 38.626 49.7739 38.626V41.2824H52.4245V38.5938ZM44.2471 38.6764V44.0375H46.8069V38.6764H44.2471Z"
        fill="white"
      />
      <path
        d="M2.6713 38.6331H5.38022C5.42779 37.6467 5.46738 36.8259 5.51139 35.9135C6.42029 35.8697 7.24225 35.8301 8.17671 35.7851V22.1494H19.2507V24.8093L19.284 24.7785H16.4884V27.5739L16.5199 27.5424C15.5892 27.5541 14.6585 27.5658 13.7277 27.5774C13.7277 26.6846 13.7277 25.7918 13.7277 24.8949H11.0711V27.5454H13.7599L13.7551 30.3396H11.0729V33.071H13.7576V35.7739C12.7053 35.837 11.8891 35.886 11.0676 35.9353V44.1266H13.7576V52.4159C14.7874 52.4159 15.6533 52.4159 16.5192 52.4159C16.5089 53.3483 16.4987 54.2807 16.4884 55.2131L16.5192 55.1798H13.8136C13.7651 56.1453 13.7242 56.9618 13.6787 57.8672H8.22935V52.4184L8.19603 52.4492H10.8794V49.6538H8.19603L8.22753 49.6853V46.8898H5.4319L5.46523 46.9206V44.1477H0.00195312V41.4134H2.7034V38.6011L2.6713 38.6331Z"
        fill="white"
      />
      <path
        d="M19.2505 24.8098H22.0225V16.5881H24.7341C24.7766 17.4734 24.816 18.2952 24.8588 19.1872H27.4678C27.5192 18.3718 27.571 17.5519 27.6362 16.5181C28.5445 16.5181 29.4433 16.5181 30.3421 16.5181C30.3421 16.5181 30.3088 16.4873 30.3088 16.4873V19.2519H33.1062C33.0951 20.1835 33.084 21.1151 33.0729 22.0466C33.0729 22.0466 33.1044 22.0152 33.1044 22.0152H27.677V24.7258C28.6109 24.7847 29.4304 24.8364 30.2443 24.8877V27.5587H24.8792V30.2599C25.7758 30.3032 26.5961 30.3428 27.4963 30.3863C27.5415 31.3061 27.5818 32.1262 27.6297 33.1023H33.1044L33.0729 33.0708V35.8663L33.1044 35.8348H22.0987C22.0484 36.8474 22.0077 37.6644 21.9596 38.632C20.9878 38.632 20.1204 38.632 19.253 38.632C19.253 37.7413 19.253 36.8506 19.253 35.9209C18.2505 35.8663 17.4375 35.822 16.4863 35.7702C16.4863 34.7973 16.4863 33.9345 16.4863 33.0716C17.3773 33.0716 18.2683 33.0716 19.1713 33.0716V30.3377H16.4863C16.4974 29.4061 16.5085 28.4746 16.5197 27.543L16.4882 27.5745H19.2838V24.7791L19.2505 24.8098Z"
        fill="white"
      />
      <path
        d="M0 19.1863V0.000976562H19.1868V19.1863H0ZM16.3837 16.4246V2.80386H2.8031V16.4246H16.3837Z"
        fill="white"
      />
      <path
        d="M79.9969 -0.000976562V19.184H60.8105V-0.000976562H79.9969ZM77.2325 16.4198V2.76322H63.575V16.4198H77.2325Z"
        fill="white"
      />
      <path
        d="M19.1794 79.9988H0.0078125V60.8135H19.1794V79.9988ZM16.4224 63.5703H2.76481V77.2268H16.4224V63.5703Z"
        fill="white"
      />
      <path
        d="M55.1842 13.7525H57.9388V21.9872H63.4766C63.4766 24.8718 63.4766 27.6039 63.4766 30.336C63.4766 30.336 63.5099 30.3052 63.5099 30.3052H57.9466C57.9466 29.2711 57.9466 28.4079 57.9466 27.5447C58.8395 27.5447 59.7323 27.5447 60.6293 27.5447V24.8883H57.9786V27.5769H55.1612V22.0468C54.1519 22.0468 53.2873 22.0468 52.4226 22.0468C52.4329 21.1143 52.4431 20.1819 52.4534 19.2495L52.4219 19.281H55.2175V13.7217L55.1842 13.7525Z"
        fill="white"
      />
      <path
        d="M30.3395 71.7638C29.4081 71.7638 28.4767 71.7638 27.5453 71.7638C27.5453 71.7638 27.5761 71.7971 27.5761 71.7971V69.1139H24.7805V71.7971L24.8112 71.7638H22.1172V63.5732H24.7351C24.7727 64.4346 24.8073 65.2257 24.847 66.1341H27.5779V60.7107L27.5446 60.7415H33.1043L33.0728 60.71V66.1807C32.0718 66.2373 31.2559 66.2834 30.3087 66.3369V71.7971L30.3395 71.7638Z"
        fill="white"
      />
      <path
        d="M8.22856 52.4182H5.45281V57.8786H0.0390625V46.9204C1.82288 46.9204 3.64366 46.9204 5.46444 46.9204C5.46444 46.9204 5.43111 46.8896 5.43111 46.8896V49.6851H8.22674L8.19524 49.6536V52.449L8.22856 52.4182Z"
        fill="white"
      />
      <path
        d="M27.5781 60.7107H22.1128C22.0613 59.7923 22.0156 58.9753 21.9616 58.0108H16.5191C16.5191 56.9687 16.5191 56.0745 16.5191 55.1803C16.5191 55.1803 16.4883 55.2136 16.4883 55.2136H22.0147C22.0147 54.1462 22.0147 53.2826 22.0147 52.4189H27.5781C27.567 55.1931 27.5559 57.9673 27.5448 60.7415L27.5781 60.7107Z"
        fill="white"
      />
      <path
        d="M44.1295 74.5266V77.3013H38.6833C38.6361 78.2789 38.5964 79.0995 38.5533 79.9924H33.1056V74.5291L33.0723 74.5599H35.8371V71.7627H41.3107C41.3644 72.7883 41.4071 73.6031 41.4571 74.5599H44.1603L44.1295 74.5266Z"
        fill="white"
      />
      <path
        d="M66.2772 30.3336V22.1175H71.7224C71.7652 22.9915 71.8053 23.8115 71.8492 24.7093H74.4883C74.5306 23.824 74.57 23.0026 74.6126 22.1123H77.2356V27.4815H69.1255C69.064 28.4511 69.012 29.2705 68.9466 30.3015H66.2451L66.2772 30.3336Z"
        fill="white"
      />
      <path
        d="M74.5332 63.5044H77.2478C77.2938 64.4493 77.3337 65.2693 77.3785 66.1895C78.2782 66.233 79.0982 66.2726 79.9953 66.3159V68.9553C79.109 68.9977 78.289 69.0368 77.3967 69.0794V71.7463H79.9603V77.1903H77.4039C77.3551 76.4116 77.3038 75.5919 77.2373 74.53C76.3297 74.53 75.4315 74.53 74.5332 74.53L74.5665 74.5608V63.4736L74.5332 63.5044Z"
        fill="white"
      />
      <path
        d="M77.2952 33.0732V35.7818C78.2828 35.8296 79.1038 35.8692 79.9982 35.9125V41.3597C78.1767 41.3597 76.356 41.3597 74.5353 41.3597C74.5353 41.3597 74.5661 41.393 74.5661 41.393V38.5976H71.7705L71.8038 38.6283V35.9258C72.7245 35.8766 73.5386 35.8332 74.47 35.7835C74.5186 34.9257 74.5647 34.1114 74.6216 33.1053H77.3273L77.2952 33.0732Z"
        fill="white"
      />
      <path
        d="M69.007 38.632C69.9393 38.6311 70.8715 38.6303 71.8038 38.6294C71.8038 38.6294 71.7704 38.5986 71.7704 38.5986V41.394H74.5661C74.5661 41.394 74.5353 41.3607 74.5353 41.3607C74.5353 42.2252 74.5353 43.0896 74.5353 44.0651C73.6133 44.1147 72.7991 44.1585 71.867 44.2086C71.8183 45.0652 71.7721 45.8796 71.7149 46.8863H69.0093L69.0414 46.9184V44.2224C68.0881 44.1706 67.2756 44.1265 66.2755 44.0722V41.3633C66.2755 41.3633 66.2422 41.394 66.2422 41.394H69.0378V38.5986L69.007 38.632Z"
        fill="white"
      />
      <path
        d="M63.4764 33.0699C63.4757 34.9218 63.4749 36.7737 63.4741 38.6255C63.4741 38.6255 63.5062 38.5935 63.5062 38.5935H60.8012C60.7548 37.6643 60.714 36.8482 60.6634 35.8338C57.8729 35.8338 55.1464 35.8338 52.4199 35.8338C52.4199 35.8339 52.4532 35.8646 52.4532 35.8646C52.443 34.9322 52.4327 33.9998 52.4225 33.0674C56.1182 33.0785 59.814 33.0896 63.5097 33.1007C63.5097 33.1007 63.4764 33.0699 63.4764 33.0699Z"
        fill="white"
      />
      <path
        d="M27.5804 5.43384H22.1201V2.74522C22.9969 2.70278 23.8176 2.66307 24.7332 2.61876C24.7767 1.71319 24.8162 0.891547 24.8591 -0.000976562H32.968V2.61175H27.5482C27.5482 3.6688 27.5482 4.5673 27.5482 5.46585C27.5483 5.4659 27.5804 5.43384 27.5804 5.43384Z"
        fill="white"
      />
      <path
        d="M60.745 74.5279H57.972V79.9908H55.2374V77.2895H52.4249L52.4569 77.3216C52.4563 76.3912 52.4558 75.4608 52.4552 74.5304L52.4219 74.5612H55.1268C55.1882 73.5155 55.2361 72.7001 55.2846 71.874H60.7143V74.5612L60.745 74.5279Z"
        fill="white"
      />
      <path d="M80.0002 49.792V60.6424H77.3955V49.792H80.0002Z" fill="white" />
      <path
        d="M69.0089 46.8849V49.5823H60.8135V44.2217H63.4006C63.4522 45.036 63.5042 45.8562 63.5714 46.9171C65.3981 46.9171 67.2195 46.9171 69.0409 46.9171L69.0089 46.8849Z"
        fill="white"
      />
      <path
        d="M27.5723 77.2888H22.1318V74.625C22.9513 74.5776 23.7671 74.5304 24.811 74.47C24.811 73.5499 24.811 72.6563 24.811 71.7627C24.811 71.7627 24.7802 71.796 24.7802 71.796H27.5759C27.5759 71.796 27.5451 71.7627 27.5451 71.7627C27.5435 73.6154 27.5419 75.4681 27.5402 77.3209C27.5402 77.3209 27.5723 77.2888 27.5723 77.2888Z"
        fill="white"
      />
      <path
        d="M55.1836 2.69777H57.8714V8.19555C56.9688 8.19555 56.0762 8.19555 55.1836 8.19555C55.1836 8.19556 55.2169 8.22633 55.2169 8.22633V2.66699L55.1836 2.69777Z"
        fill="white"
      />
      <path
        d="M2.70259 38.6003H0.0107422V33.1865H2.67037C2.67037 35.0069 2.67037 36.8196 2.67043 38.6323C2.67049 38.6324 2.70259 38.6003 2.70259 38.6003Z"
        fill="white"
      />
      <path
        d="M63.4736 38.6267C64.4066 38.6284 65.3396 38.63 66.2727 38.6316L66.2419 38.5983V41.3937C66.2419 41.3937 66.2752 41.3629 66.2752 41.3629C65.4143 41.3629 64.5534 41.3629 63.5059 41.3629C63.5059 40.3718 63.5059 39.4833 63.5058 38.5947C63.5057 38.5947 63.4736 38.6267 63.4736 38.6267Z"
        fill="white"
      />
      <path d="M77.3916 46.8216V44.2256H79.9949V46.8216H77.3916Z" fill="white" />
      <path
        d="M66.273 38.6311V35.9463H69.007C69.007 36.8493 69.007 37.7402 69.007 38.6311C69.007 38.6311 69.0378 38.5978 69.0378 38.5978H66.2422L66.273 38.6311Z"
        fill="white"
      />
      <path
        d="M77.3269 33.1056V30.417H79.9776V33.0734C79.0806 33.0734 78.1878 33.0734 77.2949 33.0735L77.3269 33.1056Z"
        fill="white"
      />
      <path
        d="M66.2455 30.3018V33.0705C65.2535 33.0705 64.365 33.0705 63.4766 33.0705C63.4766 33.0705 63.5099 33.1013 63.5099 33.1013V30.3059C63.5099 30.3059 63.4766 30.3367 63.4766 30.3367C64.4102 30.3358 65.3438 30.3349 66.2775 30.3339C66.2776 30.3339 66.2455 30.3018 66.2455 30.3018Z"
        fill="white"
      />
      <path d="M5.34993 30.2584H2.78125V27.6553H5.34993V30.2584Z" fill="white" />
      <path d="M5.34225 24.7293H2.78711V22.1338H5.34225V24.7293Z" fill="white" />
      <path
        d="M33.106 74.5292H30.3394C30.3394 73.539 30.3394 72.6509 30.3394 71.7627C30.3394 71.7627 30.3086 71.796 30.3086 71.796C31.2402 71.7849 32.1719 71.7738 33.1035 71.7627C33.0932 72.6951 33.083 73.6275 33.0727 74.5599L33.106 74.5292Z"
        fill="white"
      />
      <path
        d="M52.4547 74.5291H49.688C49.688 73.539 49.688 72.6508 49.688 71.7627C49.688 71.7627 49.6572 71.796 49.6572 71.796H52.4214V74.5599L52.4547 74.5291Z"
        fill="white"
      />
      <path
        d="M63.5113 77.2928H60.7447C60.7447 76.3027 60.7447 75.4145 60.7447 74.5264L60.7139 74.5597H63.5095L63.478 74.5282V77.3236L63.5113 77.2928Z"
        fill="white"
      />
      <path
        d="M27.54 77.3221H30.2289V79.9725H27.5723C27.5723 79.0756 27.5723 78.1828 27.5722 77.29C27.5721 77.29 27.54 77.3221 27.54 77.3221Z"
        fill="white"
      />
      <path
        d="M52.4241 77.2881V79.9767H49.7734V77.3203C50.6704 77.3203 51.5632 77.3203 52.4561 77.3202C52.4562 77.3202 52.4241 77.2881 52.4241 77.2881Z"
        fill="white"
      />
      <path d="M35.8679 71.7955H33.0723V69.1123H35.8679V71.7955Z" fill="white" />
      <path d="M49.6572 49.6851V46.8896H52.3406V49.6851H49.6572Z" fill="white" />
      <path
        d="M46.8936 49.6533H49.6892C49.6892 50.5851 49.6892 51.5169 49.6892 52.4487H46.8936V49.6533Z"
        fill="white"
      />
      <path d="M49.6572 55.2124V52.417H52.3406V55.2124H49.6572Z" fill="white" />
      <path d="M46.8936 57.9761V55.1807H49.6892V57.9761H46.8936Z" fill="white" />
      <path d="M60.8076 63.3697V60.8076H63.4109V63.3697H60.8076Z" fill="white" />
      <path d="M52.4528 33.1011H44.1289V30.3057H52.4528V33.1011Z" fill="white" />
      <path d="M46.8936 24.7783H49.6892V27.4615H46.8936V24.7783Z" fill="white" />
      <path d="M49.6572 24.8101V22.0146H52.4529V24.8101H49.6572Z" fill="white" />
      <path d="M44.1289 19.2827V16.4873H46.9245V19.2827H44.1289Z" fill="white" />
      <path
        d="M33.0723 19.2827V16.4873C34.0041 16.4873 34.936 16.4873 35.8679 16.4873C35.8679 17.4191 35.8679 18.3509 35.8679 19.2827C34.936 19.2827 34.0041 19.2827 33.0723 19.2827Z"
        fill="white"
      />
      <path
        d="M27.5443 52.4497V46.9219H24.7801V44.2084C23.8311 44.1624 23.0095 44.1226 22.1143 44.0792V41.4145H24.6959C24.7548 40.4828 24.8067 39.6627 24.874 38.5986H33.104V44.1615C32.093 44.1615 31.2025 44.1615 30.3118 44.1615C30.3118 43.2687 30.3118 42.3759 30.3118 41.479H27.6552V44.1295H30.344V49.6543H33.104C33.104 50.5861 33.104 51.5179 33.104 52.4497H27.5443Z"
        fill="white"
      />
      <path d="M22.048 49.6533V52.4487H16.4883V49.6533H22.048Z" fill="white" />
      <path
        d="M13.7236 44.1577V41.3623H16.5193C16.5074 42.2931 16.4955 43.2239 16.4837 44.1547L16.5158 44.1227C15.585 44.1344 14.6543 44.146 13.7236 44.1577Z"
        fill="white"
      />
      <path
        d="M16.4834 44.1541H19.1722V46.8046H16.5156C16.5156 45.9076 16.5156 45.0149 16.5156 44.1221C16.5155 44.122 16.4834 44.1541 16.4834 44.1541Z"
        fill="white"
      />
      <path d="M16.4883 41.394V38.5986H19.2839V41.394H16.4883Z" fill="white" />
      <path d="M24.8122 46.8896V49.6851H22.0166V46.8896H24.8122Z" fill="white" />
      <path d="M35.8679 49.6842H33.0723V47.001H35.8679V49.6842Z" fill="white" />
      <path
        d="M16.5193 30.3057V33.1011C15.5874 33.1011 14.6555 33.1011 13.7236 33.1011C13.7236 32.1693 13.7236 31.2375 13.7236 30.3057H16.5193Z"
        fill="white"
      />
      <path d="M13.6215 13.6606H5.56445V5.56348H13.6215V13.6606Z" fill="white" />
      <path d="M74.433 13.6606H66.376V5.56348H74.433V13.6606Z" fill="white" />
      <path d="M5.52832 74.4641V66.335H13.6581V74.4641H5.52832Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2860_52922">
        <rect width="80" height="80" fill="white" transform="translate(0 -0.000976562)" />
      </clipPath>
    </defs>
  </svg>
);
