import { extUrl } from '../../utils/consts';

export const menuItems = [
  {
    nameItem: 'Компания',
    children: [
      { nameItem: 'О компании', link: `${extUrl}/o-kompanii` },
      { nameItem: 'Наше производство', link: `${extUrl}/o-kompanii/proizvodstvo` },
      { nameItem: 'Отзывы клиентов', link: `${extUrl}/o-kompanii/otzyvy` },
      { nameItem: 'Вакансии', link: `${extUrl}/o-kompanii/vakansii` },
      { nameItem: 'Контакты', link: `${extUrl}/kontakty` },
      {
        nameItem: 'Партнерская программа',
        link: `${extUrl}/akcii/partnerskaya-programma`,
      },
      { nameItem: 'Договор оферты', link: `${extUrl}/dogovor-oferty` },
    ],
  },
  {
    nameItem: 'Продукция',
    children: [
      { nameItem: 'Пластиковые окна', link: `${extUrl}/plastikovye-okna` },
      { nameItem: 'Пластиковые окна РЕХАУ', link: `${extUrl}/plastikovye-okna-rehau` },
      { nameItem: 'Стеклопакеты', link: `${extUrl}/plastikovye-okna/steklopaket` },
      { nameItem: 'Двери', link: `${extUrl}/dveri` },
      { nameItem: 'Готовые окна', link: `${extUrl}/ceny/gotovie-okna-pvh` },
      { nameItem: 'Аксессуары', link: `${extUrl}/plastikovye-okna/aksesuary` },
    ],
  },
  {
    nameItem: 'Услуги',
    children: [
      { nameItem: 'Остекление квартир', link: `${extUrl}/ceny/osteklenie-kvartiry` },
      { nameItem: 'Остекление балконов', link: `${extUrl}/balkony-lodzhii` },
      {
        nameItem: 'Отделка балконов',
        link: `${extUrl}/balkony-lodzhii/otdelka-balkonov-i-lodzhii`,
      },
      { nameItem: 'Остекление офисов', link: `${extUrl}/ofisnoe-osteklenie` },
      { nameItem: 'Остекление загородных домов', link: `${extUrl}/zagorodnoe-osteklenie` },
      {
        nameItem: 'Установка пластиковых окон',
        link: `${extUrl}/uslugi/montazh-okon-pvh-gost`,
      },
    ],
  },
  {
    nameItem: 'Цены и оплата',
    children: [
      {
        nameItem: 'Калькулятор стоимости окон',
        link: `${extUrl}/plastikovye-okna-rehau/0`,
      },
      { nameItem: 'Цены на окна', link: `${extUrl}/ceny` },
      { nameItem: 'Как купить', link: `${extUrl}/ceny/kak-kupit` },
      { nameItem: 'Оплатить заказ', link: `${extUrl}/oplata` },
      { nameItem: 'Рассрочка', link: `${extUrl}/ceny/rassrochka` },
    ],
  },
];

export const secondMenu = [
  {
    nameItem: 'Сертификаты',
    link: '/o-kompanii/sertifikaty',
  },
  {
    nameItem: 'Статьи',
    link: '/stati',
  },
  {
    nameItem: 'Политика конфидециальности',
    link: '/privacy-policy',
  },
];
