import React from 'react';

export interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const RehauLogo = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="24"
    viewBox="0 0 72 24"
    fill="none"
    {...props}
  >
    <path d="M11.3673 11.7803H9.3252V13.8297H11.3673V11.7803Z" fill="white" />
    <path d="M8.56068 11.7803H6.51855V13.8297H8.56068V11.7803Z" fill="white" />
    <path d="M14.1749 8.96094H12.1328V11.0104H14.1749V8.96094Z" fill="white" />
    <path d="M11.3673 8.96094H9.3252V11.0104H11.3673V8.96094Z" fill="white" />
    <path d="M8.56068 8.96094H6.51855V11.0104H8.56068V8.96094Z" fill="white" />
    <path d="M5.75306 8.96094H3.71094V11.0104H5.75306V8.96094Z" fill="white" />
    <path d="M14.1749 6.14453H12.1328V8.19397H14.1749V6.14453Z" fill="white" />
    <path d="M11.3673 6.14453H9.3252V8.19397H11.3673V6.14453Z" fill="white" />
    <path d="M8.56068 6.14453H6.51855V8.19397H8.56068V6.14453Z" fill="white" />
    <path d="M5.75306 6.14453H3.71094V8.19397H5.75306V6.14453Z" fill="white" />
    <path d="M2.94447 6.14453H0.902344V8.19397H2.94447V6.14453Z" fill="white" />
    <path d="M14.1749 3.32617H12.1328V5.37561H14.1749V3.32617Z" fill="white" />
    <path d="M11.3673 3.32617H9.3252V5.37561H11.3673V3.32617Z" fill="white" />
    <path d="M8.56068 3.32617H6.51855V5.37561H8.56068V3.32617Z" fill="white" />
    <path d="M5.75306 3.32617H3.71094V5.37561H5.75306V3.32617Z" fill="white" />
    <path d="M2.94447 3.32617H0.902344V5.37561H2.94447V3.32617Z" fill="white" />
    <path d="M11.3673 0.507812H9.3252V2.55726H11.3673V0.507812Z" fill="white" />
    <path d="M8.56068 0.507812H6.51855V2.55726H8.56068V0.507812Z" fill="white" />
    <path d="M5.75306 0.507812H3.71094V2.55726H5.75306V0.507812Z" fill="white" />
    <path
      d="M71.097 8.19238V21.0009C71.097 22.2818 70.3312 23.5627 68.7996 23.5627H62.0353V20.4886H66.247C66.8854 20.4886 67.0128 20.2324 67.0128 19.72V18.8236H63.5669C62.0353 18.8236 61.2695 17.5427 61.2695 16.2618V8.19481L65.3538 8.19287V15.4938C65.3538 16.134 65.609 16.2623 66.1196 16.2623H67.0128V8.19287H71.097V8.19238Z"
      fill="white"
    />
    <path
      d="M51.9527 8.19238C50.4211 8.19238 49.6553 9.47329 49.6553 10.7542V23.5618H53.739L53.741 17.1587H55.398V23.5627H59.4823V8.19238H51.9527ZM55.3985 14.5964H53.7395V11.5222C53.7395 10.882 53.9948 10.7571 54.5053 10.7571L55.3985 10.7537V14.5964Z"
      fill="white"
    />
    <path
      d="M47.8695 18.4396V23.5632H43.7852V17.1592H42.892C42.3815 17.1592 42.1262 17.2875 42.1262 17.9277V23.5613H38.042L38.0439 18.4401C38.0439 17.1592 38.6804 16.0066 39.7015 15.8754C38.6804 15.7504 38.0425 14.5974 38.0439 13.3165L38.042 8.19287H42.1262V14.5969H43.0194C43.53 14.5969 43.7852 14.469 43.7852 13.8284V8.19238H47.8695V13.316C47.8709 14.5969 47.2316 15.7495 46.2105 15.8749C47.2316 16.0056 47.8714 17.1587 47.8695 18.4396Z"
      fill="white"
    />
    <path
      d="M33.3196 14.5964H35.8698V17.1582H33.3196V20.4808L36.2549 20.4886V23.5622H29.2354V8.1953L36.2549 8.19238V11.2661H33.3196V14.5964Z"
      fill="white"
    />
    <path
      d="M25.1514 8.19238L18.1318 8.19287V23.5608H22.2146V19.4634H25.1514C26.683 19.4634 27.4488 18.1825 27.4488 16.9016V10.7537C27.4488 9.4728 26.683 8.19141 25.1514 8.19238ZM23.8751 16.1335C23.8751 16.7742 23.6198 16.902 23.1093 16.902H22.2156C22.2141 16.9011 22.2141 10.7542 22.2141 10.7542H23.1088C23.6193 10.7542 23.8746 10.8825 23.8746 11.5227L23.8751 16.1335Z"
      fill="white"
    />
  </svg>
);
