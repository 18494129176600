import React from 'react';

export interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const VKIcon = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_2860_52860)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5603 15.6855C28.6388 15.313 28.466 15.1042 28.0576 15.1042H26C25.5288 15.1042 25.356 15.298 25.2147 15.5812C25.2147 15.5812 24.1022 17.8302 22.7487 19.2475C22.3142 19.7056 22.0933 19.8435 21.8534 19.8435C21.6599 19.8435 21.5707 19.6905 21.5707 19.2772V15.6557C21.5707 15.1491 21.5079 15 21.0681 15H17.7697C17.5183 15 17.3612 15.1386 17.3612 15.3278C17.3612 15.805 18.1309 15.9127 18.1309 17.2056V19.8733C18.1309 20.4099 18.0995 20.6185 17.8325 20.6185C17.1257 20.6185 15.4451 18.3085 14.5027 15.6855C14.3142 15.1491 14.11 15 13.5759 15H11.5184C11.2199 15 11 15.1937 11 15.4768C11 15.9984 11.644 18.4128 14.1728 21.6468C15.8691 23.8227 18.0995 25 20.1099 25C21.3351 25 21.6335 24.8063 21.6335 24.3145V22.6453C21.6335 22.228 21.8063 22.0492 22.0576 22.0492C22.3403 22.0492 22.8393 22.1354 24.0053 23.2265C25.3874 24.4785 25.4817 25 26.2357 25H28.5445C28.7801 25 29 24.8957 29 24.5231C29 24.0313 28.3246 23.152 27.2879 22.1088C26.8639 21.5723 26.1728 20.9911 25.9529 20.7229C25.6388 20.4099 25.733 20.2309 25.9529 19.9031C25.9529 19.9031 28.3246 16.7288 28.5603 15.6855Z"
        fill="#151515"
      />
    </g>
    <defs>
      <clipPath id="clip0_2860_52860">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const YaIcon = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_2860_52863)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6704 9C19.615 13.4593 19.3106 15.9656 17.6381 17.6381C15.9656 19.3106 13.4593 19.615 9 19.6703V20.3296C13.4593 20.385 15.9656 20.6894 17.6381 22.3619C19.3106 24.0344 19.615 26.5407 19.6704 31H20.3296C20.385 26.5407 20.6894 24.0344 22.3619 22.3619C24.0344 20.6894 26.5407 20.385 31 20.3296V19.6703C26.5407 19.6149 24.0344 19.3106 22.3619 17.6381C20.6894 15.9656 20.385 13.4592 20.3296 9H19.6704Z"
        fill="#151515"
      />
    </g>
    <defs>
      <clipPath id="clip0_2860_52863">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const TelegramIcon = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_2860_52866)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="white"
      />
      <path
        d="M16.2783 21.5679L16.0136 25.1975C16.3923 25.1975 16.5563 25.0389 16.7529 24.8484L18.5283 23.1942L22.2071 25.8208C22.8818 26.1874 23.3571 25.9943 23.5391 25.2157L25.9538 14.1839L25.9545 14.1833C26.1685 13.2109 25.5938 12.8306 24.9365 13.0692L10.7428 18.3673C9.77411 18.7339 9.78878 19.2604 10.5781 19.4989L14.2069 20.5994L22.6358 15.4573C23.0324 15.2012 23.3931 15.3429 23.0964 15.599L16.2783 21.5679Z"
        fill="#151515"
      />
    </g>
    <defs>
      <clipPath id="clip0_2860_52866">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
