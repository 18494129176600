import Footer from '../Footer';
import Header from '../Header';
import React from 'react';

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    // <div className="min-h-screen flex flex-col">
    <div className="flex flex-col">
      <Header />
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  );
}
