import React from 'react';

export interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const LogoSmall = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="56"
    viewBox="0 0 46 56"
    fill="none"
    {...props}
  >
    <path
      d="M22.6609 55.9989C16.6534 55.9905 10.8943 53.5582 6.6463 49.2351C2.39834 44.9121 0.00822385 39.0512 0 32.9375H6.47809C6.47809 37.3061 8.18339 41.4959 11.2188 44.585C14.2543 47.6741 18.3712 49.4095 22.664 49.4095C26.9568 49.4095 31.0737 47.6741 34.1092 44.585C37.1446 41.4959 38.8499 37.3061 38.8499 32.9375H45.3218C45.3135 39.0512 42.9234 44.9121 38.6755 49.2351C34.4275 53.5582 28.6684 55.9905 22.6609 55.9989Z"
      fill="#FF0000"
    />
    <path
      d="M45.2907 24.71H38.8126V9.56345L24.4762 19.6168C23.9375 19.9936 23.2991 20.1953 22.6453 20.1953C21.9916 20.1953 21.3532 19.9936 20.8144 19.6168L6.47809 9.55713V24.71H1.89427e-06V3.29986C-0.000636504 2.69904 0.160099 2.10947 0.464836 1.59487C0.769573 1.08027 1.20672 0.660219 1.72902 0.38012C2.25133 0.100021 2.83893 -0.0294739 3.42831 0.00563286C4.01769 0.0407396 4.58643 0.239112 5.07306 0.57931L22.6422 12.8661L40.2176 0.57931C40.7043 0.239112 41.273 0.0407396 41.8624 0.00563286C42.4518 -0.0294739 43.0394 0.100021 43.5617 0.38012C44.084 0.660219 44.5211 1.08027 44.8259 1.59487C45.1306 2.10947 45.2913 2.69904 45.2907 3.29986V24.71Z"
      fill="#FF0000"
    />
  </svg>
);
