import React, { useState } from 'react';

import { ErrorMessage } from 'formik';

import MaskedInput from 'react-text-mask';

import clsx from 'clsx';

import { CrossRedIcon } from '../Icons/Cross';
import { DoneIcon } from '../Icons/Done';
import { phoneMask } from '../../utils/phoneMask';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onClear?: () => void;
  ok?: boolean;
  noBorder?: boolean;
  animatedPlaceholder?: boolean;
  field?: any;
}

export const Input = ({
  ok,
  animatedPlaceholder,
  className,
  onClear,
  noBorder,
  ...otherProps
}: Props) => {
  const [focused, setFocused] = useState(false);
  const form: any = otherProps.form;
  const field: any = otherProps.field;

  return (
    <div className={clsx('relative', className)}>
      {otherProps.type !== 'tel' && (
        <input
          className={clsx(
            'font-Istok font-bold text-base text-[#3E3E40] rounded-lg',
            !noBorder && 'border border-[#3E3E40] px-4 py-2',
            focused ? 'placeholder-up' : '',
            onClear && 'pr-10',
            className,
          )}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          {...otherProps}
          placeholder={animatedPlaceholder ? '' : otherProps.placeholder}
        />
      )}

      {otherProps.type === 'tel' && (
        <>
          <MaskedInput
            {...otherProps}
            onChange={(e) => {
              const target = e.target as HTMLInputElement;
              const value = target?.value;
              if ((value[3] === ' ' || !value?.trim()) && value[1] !== '7') {
                e.target.value = '+7(   )   -  -  ';
                e.target.setSelectionRange(3, 3);
                // } else if (value[3] === '9' && value[4] === '8' && value[5] === ' ') {
                //   const newValue = `${value}`.split('');
                //   newValue[4] = ' ';
                //   e.target.value = newValue.join('');
                //   e.target.setSelectionRange(4, 4);
                // } else if (value?.[3] && value[3] !== '9' && value[4] === ' ' && value[3] !== ' ') {
                //   const digit = `${value[3]}`;
                //   const newValue = `${value}`.split('');
                //   newValue[3] = '9';
                //   newValue[4] = digit === '8' ? ' ' : digit;
                //   e.target.value = newValue.join('');
                //   if (digit === '8') e.target.setSelectionRange(4, 4);
                //   else e.target.setSelectionRange(5, 5);
              } else if (value[3] === '8') {
                const newValue = `${value}`.split('');
                newValue[3] = ' ';
                e.target.value = newValue.join('');
                e.target.setSelectionRange(3, 3);
              } else if (value?.[1] && value[1] !== '7' && value[3] === ' ') {
                const digit = `${value[1]}`;
                const newValue = `${value}`.split('');
                newValue[1] = '7';
                if (digit === '9') {
                  newValue[3] = digit;
                  e.target.value = newValue.join('');
                  e.target.setSelectionRange(4, 4);
                } else if (digit !== '7') {
                  newValue[3] = '9';
                  newValue[4] = digit;
                  e.target.value = newValue.join('');
                  e.target.setSelectionRange(5, 5);
                }
              }
              if (!e.target.value.startsWith('+7')) {
                e.target.value = '+7' + e.target.value.substring(1);
              }
              // const spIdx = e.target.value.indexOf(' ');
              // if (spIdx >= 0) {
              //   e.target.setSelectionRange(spIdx, spIdx);
              // }

              if (otherProps.onChange) otherProps.onChange(e);
            }}
            mask={phoneMask}
            placeholderChar={'\u2000'}
            onClick={(e) => {
              const target = e.target as HTMLInputElement;
              const value = target?.value;
              if ((value[3] === ' ' || !value?.trim()) && value[1] !== '7') {
                e.currentTarget.value = '+7(   )   -  -  ';
                e.currentTarget.setSelectionRange(3, 3);
              }
              const spIdx = value.indexOf(' ');
              if (spIdx >= 0) {
                target.setSelectionRange(spIdx, spIdx);
              }
            }}
            onKeyDown={(e) => {
              const spIdx = e.currentTarget.value.indexOf(' ');
              const spIdx2 = e.currentTarget.value.indexOf('- ');
              const spIdx3 = e.currentTarget.value.indexOf(') ');
              const length = e.currentTarget.value.length;

              const min = Math.min(
                spIdx > 0 ? spIdx : 99,
                spIdx2 > 0 ? spIdx2 : 99,
                spIdx3 > 0 ? spIdx3 : 99,
              );
              if (spIdx >= 0 && (spIdx2 >= 0 || spIdx3 >= 0) && min < length) {
                e.currentTarget.setSelectionRange(min, min);
              }
            }}
            onFocus={(e) => {
              const target = e.target as HTMLInputElement;
              const value = target?.value;
              if ((value[3] === ' ' || !value?.trim()) && value[1] !== '7') {
                e.target.value = '+7(   )   -  -  ';
                e.target.setSelectionRange(3, 3);
              }
              const spIdx = e.target.value.indexOf(' ');
              if (spIdx >= 0) {
                e.target.setSelectionRange(spIdx, spIdx);
              }
            }}
            className={clsx(
              'font-Istok font-bold text-base text-[#3E3E40] rounded-lg',
              !noBorder && 'border border-[#3E3E40] px-4 py-2',
              focused ? 'placeholder-up' : '',
              onClear && 'pr-10',
              className,
            )}
            placeholder={animatedPlaceholder ? '' : otherProps.placeholder}
          />
          {form?.errors?.[field?.name] && form?.touched[field?.name] && (
            <>
              <div className="error">{form.errors[field.name]}</div>
              <ErrorMessage name={field?.name} component="div" className="error" />
            </>
          )}
        </>
      )}

      {animatedPlaceholder && (
        <span
          className={clsx(
            'absolute left-4 text-[#9B9DA1] transition-all duration-300 font-normal',
            focused && otherProps.value
              ? 'text-xs top-1 left-0'
              : clsx(
                  'text-base top-1/2 transform -translate-y-1/2 left-4',
                  otherProps.value && 'hidden',
                ),
          )}
        >
          {otherProps.placeholder}
        </span>
      )}
      {onClear && !ok && (
        <CrossRedIcon
          className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
          onClick={() => onClear()}
        />
      )}
      {ok && (
        <DoneIcon className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer" />
      )}
    </div>
  );
};
