// export const clientApiId = '2081948993ctc39386fb3b6a23d2cfbf5415b19a40b8';
export const PUBLIC_KEY = '6LcnNI0kAAAAAD-v3lnYblnI0ASltX0lqDv-_H33';

export const APP_VERSION = '1.00';

export const TELE = '+7 (495) 788-83-33';
export const TEL = `tel:${TELE.replace(/\s|\(|\)|-/g, '')}`;

export const extUrl = 'https://www.mosokna.ru';

// export const URL_API = process.env.REACT_APP_API_URL || 'https://dev.backend.mosokna.ru/api';
export const URL_API = process.env.REACT_APP_API_URL || 'https://prod.backend.mosokna.ru/api';

export const optionsItemsPerPage = [10, 25, 50, 100, 200];

export const MIN_LENGTH = 4;
export const DEBOUNCE_TIME_MS = 900;

export const HOUSE_TYPES = ['Панельный дом', 'Кирпичный', 'Сталинский', 'Новостройка'];

export const MAX_FLOOR = 45;

export const RQ_OPTS = {
  staleTime: 60000 * 15,
};
