import React from 'react';

export interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const CaretDownFill = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      d="M7.98554 11.1399L3.18931 5.6585C2.62355 5.01192 3.08273 4 3.94189 4H13.5344C14.3935 4 14.8527 5.01192 14.2869 5.6585L9.4907 11.1399C9.09228 11.5952 8.38395 11.5952 7.98554 11.1399Z"
      fill="white"
    />
  </svg>
);
