import 'swiper/css';

import { FreeMode, Mousewheel } from 'swiper/modules';
import React, { useEffect, useState } from 'react';
import {
  RootState,
  setDiscount,
  setOrder,
  setOrderFloor,
  setPlan,
  setPlans,
  setPrices,
  setProfilesInfo,
  setRooms as setRoomsRedux,
} from '../../../redux/store';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { getOptions, getProfilesInfo } from '../../../utils/httpServices/global';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../components/Button';
import { Cell } from '../../../components/Cell';
import { CellImg } from './CellImg';
import { CloseIcon } from '../../../components/Icons/Close';
import Configurator from '../../Configurator';
import { Modal } from '../../../components/Modal';
import ModalFloor from '../../Configurator/ModalFloor';
import { Order } from '../../../components/Order';
import { PlanData } from './types';
import { Radio } from '../../../components/Radio';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../../utils/hooks/useIsMobile';

export default function Calc() {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const plans = useSelector((state: RootState) => state.global.plans);
  const rooms = useSelector((state: RootState) => state.global.rooms);
  const plan = useSelector((state: RootState) => state.global.plan);
  const order = useSelector((state: RootState) => state.global.order);
  const isShowOrder = useSelector((state: RootState) => state.global.isShowOrder);

  const [isLoading, setIsLoading] = useState(false);
  const [roomsNum, setRoomsNum] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState('');
  const [checked3D, setChecked3D] = useState('');
  const [checked3Didx, setChecked3Didx] = useState(0);
  const [isModalFloorOpen, setIsModalFloorOpen] = useState(false);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const plans3D = plans?.plans ? plans.plans[roomsNum] : [];

  const resetState = (isFullReset: boolean) => {
    dispatch(setDiscount(false));
    dispatch(setPrices({}));
    dispatch(setPlan(-1));
    sessionStorage.removeItem('isDefault');
  };

  const onSwiper = (swiperInstance: SwiperClass) => setSwiper(swiperInstance);

  // useEffect(() => {
  //   const newOrderData = order ? { ...order, plans } : { plans };
  //   dispatch(setOrder(newOrderData));

  //   if (plans) {
  //     sessionStorage.setItem('plans', JSON.stringify(plans));
  //   }
  // }, [plans]);

  // useEffect(() => {
  //   sessionStorage.setItem('roomsNum', JSON.stringify(roomsNum));
  //   const newOrderData = order ? { ...order, roomsNum } : { roomsNum };
  //   dispatch(setOrder(newOrderData));
  // }, [roomsNum]);

  // useEffect(() => {
  //   sessionStorage.setItem('plan', JSON.stringify(plan));
  //   const newOrderData = order ? { ...order, plan, plans } : {};
  //   dispatch(setOrder(newOrderData));
  // }, [plan]);

  useEffect(() => {
    dispatch(setDiscount(false));
    dispatch(setPrices({}));
    sessionStorage.removeItem('isDefault');
  }, [plan]);

  useEffect(() => {
    if (plans && plan >= 0 && roomsNum) {
      const selected = plans?.plans[roomsNum][plan];
      if (selected?.plan_id) {
        setIsLoading(true);

        getOptions(selected.plan_id)
          .then((res: any) => {
            if (res) {
              if (!Array.isArray(res)) {
                toast(res?.error || res, { type: 'error' });
                return;
              }
              const pl = { planData: res, planId: selected.plan_id };
              const newOrderData = order ? { ...order, ...pl } : { ...pl };
              dispatch(setOrder(newOrderData));
              dispatch(setRoomsRedux(res));
            }
          })
          .catch((error) => console.error(error))
          .finally(() => setIsLoading(false));
      }
    }
  }, [plan, plans, roomsNum]);

  useEffect(() => {
    const currentPlan = plans?.plans?.[roomsNum]?.[plan];
    if (roomsNum && plan >= 0 && currentPlan?.is_need_lifting) {
      setIsModalFloorOpen(true);
    }
  }, [plan, rooms?.length, roomsNum]);

  useEffect(() => {
    const currentPlan = plans?.plans?.[roomsNum]?.[plan];
    if (roomsNum && plan >= 0 && currentPlan?.is_need_lifting === false && order) {
      dispatch(setOrderFloor(1));
    }
  }, [plan, rooms?.length, roomsNum, order]);

  useEffect(() => {
    getProfilesInfo().then((res) => {
      if (res) dispatch(setProfilesInfo(res));
    });

    const plansSS = sessionStorage.getItem('plans');
    if (plansSS && !plans) {
      dispatch(setPlans(JSON.parse(plansSS)));
    }

    const roomsNumSS = sessionStorage.getItem('roomsNum');
    if (roomsNumSS) {
      setRoomsNum(JSON.parse(roomsNumSS));
    } else {
      setRoomsNum(0);
    }

    const planSS = sessionStorage.getItem('plan');
    if (planSS) {
      dispatch(setPlan(JSON.parse(planSS)));
    }
    sessionStorage.removeItem('isDefault');
  }, []);

  // useEffect(() => {
  //   if (isModalOpen && swiper) {
  //     const idx = plans3D.findIndex((el: any) => el?.image_3d_path === checked3D);
  //     swiper.slideTo(idx, 99);
  //   }
  // }, [isModalOpen, swiper]);

  return !isShowOrder ? (
    <>
      {/* <div className="lg:p-8 p-[20px]"> */}
      <div className="lg:p-8 p-0">
        <Cell step={1} title="Сколько у вас комнат?">
          <div className="flex lg:gap-4 gap-3">
            {plans?.rooms
              ? [...plans.rooms]
                  .sort((a: string | number, b: string | number) => +a - +b)
                  .map((el: number) => (
                    <Button
                      key={el}
                      variant="white"
                      className="lg:text-base text-sm"
                      onClick={() => {
                        resetState(true);
                        setRoomsNum(el);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          resetState(true);
                          setRoomsNum(el);
                        }
                      }}
                      selected={el === roomsNum}
                    >
                      {el}
                    </Button>
                  ))
              : null}
          </div>
        </Cell>
        <Cell step={2} title="Выберите вашу планировку">
          {roomsNum ? (
            <>
              <div className="lg:hidden">
                <Swiper
                  slidesPerView={'auto'}
                  className={'cursor-grab active:cursor-grabbing select-none'}
                  modules={[FreeMode, Mousewheel]}
                  mousewheel={{ forceToAxis: true }}
                  freeMode={{ enabled: true, sticky: false }}
                  onSwiper={onSwiper}
                >
                  {(plans?.plans ? plans.plans[roomsNum] : []).map((el: PlanData, i: number) => (
                    <SwiperSlide className={`!w-auto mr-4 last:mr-0`} key={JSON.stringify(el)}>
                      <CellImg
                        key={JSON.stringify(el)}
                        title={`Планировка ${el.plan_id}`}
                        has3D={el.image_3d_path || ''}
                        setIsModalOpen={setIsModalOpen}
                        setChecked3D={setChecked3D}
                        style={{
                          width: 180,
                          background: `url(${el.image_plan_path}) white no-repeat center`,
                          backgroundSize: '80%',
                        }}
                        onClick={() => {
                          dispatch(setDiscount(false));
                          dispatch(setPrices({}));
                          dispatch(setPlan(i));
                          if (el.image_3d_path) {
                            setChecked3D(el.image_3d_path);
                            setChecked3Didx(i);
                          }
                          // if (swiper) {
                          //   swiper.slideTo(i, 1);
                          // }
                        }}
                        selected={i === plan}
                      />
                      <Radio
                        checked={i === plan}
                        className="absolute top-3 right-3"
                        onClick={() => {
                          dispatch(setDiscount(false));
                          dispatch(setPrices({}));
                          dispatch(setPlan(i));
                        }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              <div className="grid grid-cols-4 lg:gap-9 gap-4 max-lg:hidden">
                {(plans?.plans ? plans.plans[roomsNum] : []).map((el: PlanData, i: number) => (
                  <CellImg
                    key={JSON.stringify(el)}
                    title={`Планировка ${el.plan_id}`}
                    has3D={el.image_3d_path || ''}
                    setIsModalOpen={setIsModalOpen}
                    setChecked3D={setChecked3D}
                    style={{
                      background: `url(${el.image_plan_path}) white no-repeat center`,
                      backgroundSize: '80%',
                    }}
                    onClick={() => {
                      dispatch(setDiscount(false));
                      dispatch(setPrices({}));
                      dispatch(setPlan(i));
                    }}
                    selected={i === plan}
                  />
                ))}
              </div>
              <Button
                variant={'red'}
                className="lg:hidden mt-8 w-full font-Calibri py-4 !text-base"
                onClick={() => setIsModalOpen(plans3D[0]?.image_3d_path)}
              >
                Посмотреть в 3D
              </Button>
              <Button
                variant="whiteRed"
                className="lg:mt-10 mt-2 font-Calibri font-bold lg:text-[18px] lg:w-auto w-full"
                onClick={() => {
                  sessionStorage.setItem('name', '');
                  sessionStorage.setItem('isDefault', 'Y');
                  navigate(`/calc/open-plan`);
                }}
              >
                Здесь нет моей квартиры
              </Button>
            </>
          ) : (
            <div className="lg:text-[20px] font-normal">Сначала выберите количество комнат</div>
          )}
        </Cell>

        <ModalFloor
          isLoading={isLoading}
          isModalOpen={isModalFloorOpen}
          setIsModalOpen={setIsModalFloorOpen}
        />
        {plan >= 0 && !isLoading && roomsNum && rooms?.length ? (
          <Configurator rooms={rooms} />
        ) : null}

        {isModalOpen && (
          <Modal
            className={(clsx('relative'), plans3D.length < 4 ? 'hd:w-3/4 w-[90%]' : 'w-[95%] px-0')}
            onClose={() => setIsModalOpen('')}
            hideCloseButton
          >
            <button onClick={() => setIsModalOpen('')} className="absolute right-6 top-6">
              <CloseIcon />
            </button>
            <div className="font-Istok lg:text-[30px] text-[19px] font-bold mb-1 max-lg:px-4">
              Выберите планировку
            </div>
            <div className="lg:text-[20px] text-[15px] max-lg:px-4">{plans?.address || ''}</div>
            <div className="flex gap-4 lg:rounded-xl rounded bg-[#EAEAEA] p-4 mt-6 w-full px-0">
              <Swiper
                centeredSlides={isMobile}
                slidesPerView={'auto'}
                className={'cursor-grab active:cursor-grabbing select-none'}
                modules={[FreeMode, Mousewheel]}
                mousewheel={{ forceToAxis: true }}
                freeMode={{ enabled: true, sticky: false }}
                onSwiper={onSwiper}
              >
                {plans3D.map((el: PlanData, i: number) => (
                  <SwiperSlide
                    className={`!w-auto first:ml-4 lg:mr-4 mr-2 last:mr-4`}
                    key={JSON.stringify(el)}
                  >
                    <div
                      onClick={() => {
                        setChecked3D(el.image_3d_path);
                        setChecked3Didx(i);
                        // dispatch(setDiscount(false));
                        // dispatch(setPrices({}));
                        // dispatch(
                        //   setPlan(
                        //     plans?.plans?.[roomsNum]?.findIndex(
                        //       (item: any) => item.plan_id === el.plan_id,
                        //     ),
                        //   ),
                        // );

                        // if (swiper) swiper.slideTo(i, 199);
                      }}
                      className={`lg:w-[320px] portrait:w-[80vmin] landscape:w-[33vmin] aspect-square rounded-2xl relative bg-white bg-no-repeat bg-contain`}
                      style={{
                        backgroundImage: `url(${el.image_3d_path})`,
                        border: `2px solid ${
                          checked3D === el.image_3d_path ? '#FF4545' : 'transparent'
                        }`,
                        // width: window.innerWidth < 1024 ? window.innerWidth - 52 : 320,
                      }}
                    >
                      <Radio
                        checked={checked3D === el.image_3d_path}
                        className="absolute top-3 right-3"
                      />
                    </div>
                    <div className="mt-2 font-bold font-Calibri">Планировка {el.plan_id}</div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div
              className="lg:hidden flex gap-2 items-center w-full mb-4 pb-4 px-4 bg-[#EAEAEA]"
              style={{ borderRadius: '0 0 8px 8px' }}
            >
              <Button
                variant={'whiteRed'}
                className="w-full"
                onClick={() => {
                  if (checked3Didx >= 0) {
                    if (swiper && checked3Didx > 0) swiper.slideTo(checked3Didx - 1, 199);
                    setChecked3D((p) =>
                      checked3Didx > 0 ? plans3D[checked3Didx - 1].image_3d_path : p,
                    );
                    setChecked3Didx((prev) => (prev > 0 ? prev - 1 : prev));
                  }
                }}
                disabled={checked3Didx <= 0}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M11.238 13.3399L4.90374 8.12385C4.70727 7.96206 4.71273 7.65947 4.91491 7.50488L11.238 2.66992"
                    stroke="#FF4545"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </Button>
              <Button
                variant={'whiteRed'}
                className="w-full"
                onClick={() => {
                  if (checked3Didx >= 0) {
                    if (swiper && checked3Didx < plans3D.length - 1)
                      swiper.slideTo(checked3Didx + 1, 199);
                    setChecked3D((prev) =>
                      checked3Didx < plans3D.length - 1
                        ? plans3D[checked3Didx + 1].image_3d_path
                        : prev,
                    );
                    setChecked3Didx((prev) => (prev < plans3D.length - 1 ? prev + 1 : prev));
                  }
                }}
                disabled={checked3Didx >= plans3D.length - 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M4.75977 2.66992L11.094 7.88599C11.2905 8.04778 11.285 8.35037 11.0828 8.50497L4.75977 13.3399"
                    stroke="#FF4545"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </Button>
            </div>
            <Button
              variant="red"
              className="lg:mt-6 ml-auto max-lg:mr-4"
              onClick={() => {
                dispatch(setPlan(checked3Didx));
                setIsModalOpen('');
              }}
            >
              Сохранить
            </Button>
          </Modal>
        )}
      </div>
    </>
  ) : (
    <Order />
  );
}
