import 'swiper/css';

import { FreeMode, Mousewheel } from 'swiper/modules';
import { Item, Props } from '../Options';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RootState, selectOption } from '../../redux/store';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { blockClass, blockRightStyle } from '../Options/styles';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../Button';
import { Modal } from '../Modal';
import Spinner from '../Spinner';
import { getPrice } from '../../utils/httpServices/global';
import { mockOptionsData } from '../Options/mockData';
import { useQuery } from 'react-query';
import { RQ_OPTS } from '../../utils/consts';

export const OptionsBalcony = ({ open, dataKey, data }: Props) => {
  const dispatch = useDispatch();
  const { isOptionsOpen, setIsOptionsOpen, index, indexAbs, typeKey, isLoading } = data;

  const order = useSelector((state: RootState) => state.global.order);
  const orderState = useSelector((state: RootState) => state.global.orderState);
  const options = useSelector((state: RootState) => state.global.options);
  const profilesInfo = useSelector((state: RootState) => state.global.profilesInfo);
  const roomData = order?.planData?.[indexAbs];
  const names = useSelector((state: RootState) => state.global.names);
  const prices = useSelector((state: RootState) => state.global.prices);

  const [swiperMenu, setSwiperMenu] = useState<null | SwiperClass>(null);
  const onSwiper = (swiperInstance: any) => setSwiperMenu(swiperInstance);

  const maxSize = '60vmin';
  const imgClass = `lg:w-2/3 w-full max-w-[${maxSize}] max-h-[${maxSize}] h-fit aspect-square rounded-2xl bg-cover bg-no-repeat ml-auto overflow-hidden`;
  const inBlockClass = `lg:w-1/2 w-full lg:overflow-auto lg:aspect-square lg:!max-h-[${maxSize}]`;

  const profiles = roomData?.systems;

  const [isDirty, setIsDirty] = useState(false);
  const [showSaveBtnSaved, setShowSaveBtnSaved] = useState(false);
  // const [showCompare, setShowCompare] = useState(false);

  const [system, setSystem] = useState(orderState?.profiles?.[typeKey]?.[index] || 0);
  const [opening, setOpening] = useState(orderState?.systems?.[typeKey]?.[index] || 0);

  const constructions =
    order?.[typeKey === 'windows' ? 'windowsArr1' : 'balconiesArr1']?.[index]?.systems?.[system]
      ?.constructions;

  let openSys, openVars: any, openOptions: any;
  let openOptionsArr: any[] = [];
  if (constructions) {
    openVars = Object.values(constructions);
    openOptions = openVars?.[opening]?.options;
    // openOptions = options?.[typeKey]?.[index];
    openOptionsArr = openOptions ? Object.values(openOptions) : [];
    openSys = openVars?.[opening]?.options?.system?.values?.[0];
  }

  const extdoorsillIdx = useMemo(
    () => openOptionsArr.findIndex((el: any) => el?.slug === 'extdoorsill'),
    [openOptionsArr],
  );
  const extwindsillIdx = useMemo(
    () => openOptionsArr.findIndex((el: any) => el?.slug === 'extwindsill'),
    [openOptionsArr],
  );
  const floorfinishIdx = useMemo(
    () => openOptionsArr.findIndex((el: any) => el?.slug === 'floorfinish'),
    [openOptionsArr],
  );
  const loggdecorIdx = useMemo(
    () => openOptionsArr.findIndex((el: any) => el?.slug === 'loggdecor'),
    [openOptionsArr],
  );
  const loggdecor2Idx = openOptionsArr.findIndex((el: any) => el?.slug === 'loggdecor2');

  const [extdoorsill, setExtdoorsill] = useState(
    extdoorsillIdx >= 0 ? options?.[typeKey]?.[index]?.[extdoorsillIdx]?.selected : -1,
  );
  const [extwindsill, setExtwindsill] = useState(
    extwindsillIdx >= 0 ? options?.[typeKey]?.[index]?.[extwindsillIdx]?.selected : -1,
  );
  const [floorfinish, setfloorfinish] = useState(
    floorfinishIdx >= 0 ? options?.[typeKey]?.[index]?.[floorfinishIdx]?.selected : -1,
  );
  const [loggdecor, setloggdecor] = useState(
    loggdecorIdx >= 0 ? options?.[typeKey]?.[index]?.[loggdecorIdx]?.selected : -1,
  );
  const [loggdecor2, setloggdecor2] = useState(
    loggdecor2Idx >= 0 ? options?.[typeKey]?.[index]?.[loggdecor2Idx]?.selected : -1,
  );

  useEffect(() => {
    setExtdoorsill(
      extdoorsillIdx >= 0 ? options?.[typeKey]?.[index]?.[extdoorsillIdx]?.selected : -1,
    );
    setExtwindsill(
      extwindsillIdx >= 0 ? options?.[typeKey]?.[index]?.[extwindsillIdx]?.selected : -1,
    );
    setfloorfinish(
      floorfinishIdx >= 0 ? options?.[typeKey]?.[index]?.[floorfinishIdx]?.selected : -1,
    );
    setloggdecor(loggdecorIdx >= 0 ? options?.[typeKey]?.[index]?.[loggdecorIdx]?.selected : -1);
    setloggdecor2(loggdecor2Idx >= 0 ? options?.[typeKey]?.[index]?.[loggdecor2Idx]?.selected : -1);
  }, [
    extdoorsillIdx,
    extwindsillIdx,
    floorfinishIdx,
    loggdecorIdx,
    loggdecor2Idx,
    options,
    typeKey,
    index,
  ]);

  const select = useCallback(
    (i: number, selected: number) => {
      if (i < 0) return;
      dispatch(selectOption({ type: typeKey, index, i, selected }));
    },
    [dispatch, index, typeKey],
  );

  const handleSave = () => {
    if (options?.[typeKey]?.length) {
      // const newData: any = JSON.parse(JSON.stringify(orderState));
      // let values = newData?.profiles?.[typeKey];
      // if (values.length) {
      //   newData.profiles[typeKey][index] = system;
      //   newData.systems[typeKey][index] = opening;
      //   dispatch(setOrderState(newData));
      // }

      select(extdoorsillIdx, extdoorsill);
      select(extwindsillIdx, extwindsill);
      select(floorfinishIdx, floorfinish);
      select(loggdecorIdx, loggdecor);
      select(loggdecor2Idx, loggdecor2);

      setIsDirty(false);
      setShowSaveBtnSaved(true);
      setTimeout(() => {
        setShowSaveBtnSaved(false);
      }, 2500);
    }
  };

  const [optData, setOptData] = useState<any>(null);
  const { data: optPrice, isLoading: isLoading0 } = useQuery(
    ['options', JSON.stringify(optData)],
    () => (isOptionsOpen >= 0 && optData ? getPrice(optData) : null),
    RQ_OPTS,
  );

  const isLoading1 = isLoading || isLoading0;

  useEffect(() => {
    const openingData: any = constructions ? Object.values(constructions)[opening] : null;
    const activeOptions = options?.[typeKey]?.[index];
    const activeOptionsArr = activeOptions ? Object.values(activeOptions) : [];

    const initData = {
      id: roomData?.id,
      name: roomData?.name,
      item_id: roomData?.item_id,
      construction_id: openingData?.id,
      options: openingData?.options
        ? Object.values(openingData.options).map((option: any) => {
            if (activeOptions) {
              const optItems: any = activeOptionsArr.find((el: any) => el.name === option.name);

              let selected = optItems?.selected;

              if (optItems?.slug === 'extdoorsill' && selected >= 0) {
                selected = extdoorsill;
              }
              if (optItems?.slug === 'extwindsill' && selected >= 0) {
                selected = extwindsill;
              }
              if (optItems?.slug === 'floorfinish' && selected >= 0) {
                selected = floorfinish;
              }
              if (optItems?.slug === 'loggdecor' && selected >= 0) {
                selected = loggdecor;
              }
              if (optItems?.slug === 'loggdecor2' && selected >= 0) {
                selected = loggdecor2;
              }

              let optItem: any =
                selected >= 0 ? { ...option?.values?.[selected] } : { ...option.values[0] };
              optItem.enabled = selected >= 0 && !!option?.values?.[selected];
              optItem.slug = option?.slug;

              delete optItem.image;
              delete optItem.price;
              delete optItem.color_id;
              delete optItem.unit_id;
              // delete optItem.name;

              return optItem;
            }

            return [];
          })
        : // .filter(Boolean)
          null,
    };

    setOptData(initData);
  }, [
    data,
    dispatch,
    order,
    extdoorsill,
    extwindsill,
    roomData,
    profiles,
    constructions,
    options,
    typeKey,
    index,
    names,
    profilesInfo,
    opening,
    floorfinish,
    loggdecor,
    loggdecor2,
  ]);

  const menuData = mockOptionsData
    .filter((el: any, i: number) => (typeKey === 'balconies' && i < 5 ? null : el))
    .filter(Boolean);

  useEffect(() => {
    if (isOptionsOpen >= 5 && swiperMenu) {
      swiperMenu.slideTo(isOptionsOpen - 5, 199);
    }
  }, [isOptionsOpen, swiperMenu]);

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOptionsOpen >= 5) {
        setIsOptionsOpen(-1);
      }
    };

    document.addEventListener('keydown', handleEsc);
    return () => document.removeEventListener('keydown', handleEsc);
  }, []);

  if (!open) return null;

  return (
    <Modal
      className="relative !bg-[#F4F4F4] overflow-auto w-full lg:min-h-[85dvh] lg:w-[63%]"
      classNameModal="lg:items-center"
      onClose={() => {
        if (isOptionsOpen >= 5) setIsOptionsOpen(-1);
      }}
    >
      <div className="flex lg:mb-3 lg:text-[23px]" id="options-label">
        Отделка лоджии
      </div>
      <div className="lg:hidden flex mt-4 mb-3">
        <Swiper
          slidesPerView={'auto'}
          className={'cursor-grab active:cursor-grabbing select-none'}
          onSwiper={onSwiper}
          modules={[FreeMode, Mousewheel]}
          mousewheel={{ forceToAxis: true }}
          freeMode={{ enabled: true, sticky: false }}
        >
          {menuData.map((option: any, i: number) => (
            <SwiperSlide
              className={`!w-auto lg:mr-4 mr-3 last:mr-0`}
              key={JSON.stringify(option) + i}
            >
              <Button
                variant={i + 5 === isOptionsOpen ? 'red' : 'white'}
                onClick={() => setIsOptionsOpen(i + 5)}
                style={{
                  display:
                    openOptions &&
                    (!option?.code || (option.code && option.code in openOptions)) &&
                    options?.[typeKey]?.[index]?.find(
                      (el: any) => el.name?.trim() === option.name?.trim(),
                    )?.selected >= 0
                      ? 'block'
                      : 'none',
                }}
              >
                {option.name}
              </Button>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="lg:flex hidden gap-3 mb-2">
        {menuData.map((option: any, i: number) => (
          <Button
            key={JSON.stringify(option) + i}
            variant={i + 5 === isOptionsOpen ? 'red' : 'white'}
            onClick={() => setIsOptionsOpen(i + 5)}
            className="w-fit lg:!py-[10px] lg:!px-4"
            style={{
              display:
                openOptions &&
                (!option?.code || (option.code && option.code in openOptions)) &&
                options?.[typeKey]?.[index]?.find(
                  (el: any) => el.name?.trim() === option.name?.trim(),
                )?.selected >= 0
                  ? 'block'
                  : 'none',
            }}
          >
            {option.name}
          </Button>
        ))}
      </div>

      {isOptionsOpen === 5 &&
        !!options?.[typeKey]?.[index]?.find((el: any) => el.slug === 'extdoorsill') && (
          <div className={blockClass}>
            <div className={inBlockClass}>
              {options?.[typeKey]?.[index]
                ?.find((el: any) => el.slug === 'extdoorsill')
                ?.values?.map((el: any, i: number) => (
                  <Item
                    data={{
                      ...el,
                      onClick: () => {
                        setIsDirty(true);
                        setExtdoorsill(i);
                      },
                      selected: extdoorsill === i,
                    }}
                    key={JSON.stringify(el)}
                  />
                ))}
            </div>
            {/* <div className="flex flex-auto min-h-[40vh]"> */}
            <div className={blockRightStyle}>
              <div
                className={imgClass}
                style={{
                  backgroundImage: `url(${
                    options?.[typeKey]?.[index]?.find((el: any) => el.slug === 'extdoorsill')
                      ?.values?.[extdoorsill]?.image
                  })`,
                }}
              />
            </div>
          </div>
        )}
      {isOptionsOpen === 6 &&
        !!options?.[typeKey]?.[index]?.find((el: any) => el.slug === 'extwindsill') && (
          <div className={blockClass}>
            <div className={inBlockClass}>
              {options?.[typeKey]?.[index]
                ?.find((el: any) => el.slug === 'extwindsill')
                ?.values?.map((el: any, i: number) => (
                  <Item
                    data={{
                      ...el,
                      onClick: () => {
                        setIsDirty(true);
                        setExtwindsill(i);
                      },
                      selected: extwindsill === i,
                    }}
                    key={JSON.stringify(el)}
                  />
                ))}
            </div>
            {/* <div className="flex flex-auto min-h-[40vh]"> */}
            <div className={blockRightStyle}>
              <div
                className={imgClass}
                style={{
                  backgroundImage: `url(${
                    options?.[typeKey]?.[index]?.find((el: any) => el.slug === 'extwindsill')
                      ?.values?.[extwindsill]?.image
                  })`,
                }}
              />
            </div>
          </div>
        )}

      {isOptionsOpen === 7 &&
        !!options?.[typeKey]?.[index]?.find((el: any) => el.slug === 'floorfinish') && (
          <div className={blockClass}>
            <div className={inBlockClass}>
              {options?.[typeKey]?.[index]
                ?.find((el: any) => el.slug === 'floorfinish')
                ?.values?.map((el: any, i: number) => (
                  <Item
                    data={{
                      ...el,
                      onClick: () => {
                        setIsDirty(true);
                        setfloorfinish(i);
                      },
                      selected: floorfinish === i,
                    }}
                    key={JSON.stringify(el)}
                  />
                ))}
            </div>
            {/* <div className="flex flex-auto min-h-[40vh]"> */}
            <div className={blockRightStyle}>
              <div
                className={imgClass}
                style={{
                  backgroundImage: `url(${
                    options?.[typeKey]?.[index]?.find((el: any) => el.slug === 'floorfinish')
                      ?.values?.[floorfinish]?.image
                  })`,
                }}
              />
            </div>
          </div>
        )}

      {isOptionsOpen === 8 &&
        !!options?.[typeKey]?.[index]?.find((el: any) => el.slug === 'loggdecor') && (
          <div className={blockClass}>
            <div className={inBlockClass}>
              {options?.[typeKey]?.[index]
                ?.find((el: any) => el.slug === 'loggdecor')
                ?.values?.map((el: any, i: number) => (
                  <Item
                    data={{
                      ...el,
                      onClick: () => {
                        setIsDirty(true);
                        setloggdecor(i);
                      },
                      selected: loggdecor === i,
                    }}
                    key={JSON.stringify(el)}
                  />
                ))}
            </div>
            {/* <div className="flex flex-auto min-h-[40vh]"> */}
            <div className={blockRightStyle}>
              <div
                className={imgClass}
                style={{
                  backgroundImage: `url(${
                    options?.[typeKey]?.[index]?.find((el: any) => el.slug === 'loggdecor')
                      ?.values?.[loggdecor]?.image
                  })`,
                }}
              />
            </div>
          </div>
        )}

      {isOptionsOpen === 9 &&
        !!options?.[typeKey]?.[index]?.find((el: any) => el.slug === 'loggdecor2') && (
          <div className={blockClass}>
            <div className={inBlockClass}>
              {options?.[typeKey]?.[index]
                ?.find((el: any) => el.slug === 'loggdecor2')
                ?.values?.map((el: any, i: number) => (
                  <Item
                    data={{
                      ...el,
                      onClick: () => {
                        setIsDirty(true);
                        setloggdecor2(i);
                      },
                      selected: loggdecor2 === i,
                    }}
                    key={JSON.stringify(el)}
                  />
                ))}
            </div>
            {/* <div className="flex flex-auto min-h-[40vh]"> */}
            <div className={blockRightStyle}>
              <div
                className={imgClass}
                style={{
                  backgroundImage: `url(${
                    options?.[typeKey]?.[index]?.find((el: any) => el.slug === 'loggdecor2')
                      ?.values?.[loggdecor2]?.image
                  })`,
                }}
              />
            </div>
          </div>
        )}

      <div
        className="bg-white rounded-[20px] py-5 px-4 flex justify-between mt-3"
        style={{
          boxShadow:
            '0px 8px 12.7px 0px rgba(0, 0, 0, 0.19), 0px -3px 12.7px 0px rgba(0, 0, 0, 0.19)',
        }}
      >
        <div className="lg:block flex flex-col justify-between">
          <p className="font-Istok lg:text-[22px] text-[19px] lg:mb-1">Цена окна</p>
          <p className="text-base font-normal leading-none flex flex-wrap">
            с учетом выбранных опций
          </p>
        </div>

        <div>
          <div
            className="mb-3 text-[#FF4545] font-Istok text-[22px] w-fit ml-auto transition-all flex items-center"
            // style={{ opacity: isDirty ? 0 : 1 }}
          >
            {isLoading1 ? (
              <Spinner />
            ) : (
              <>
                {prices?.rooms?.[roomData?.item_id] && !isDirty
                  ? prices?.rooms?.[roomData?.item_id]?.total
                  : optPrice?.total || ''}
              </>
            )}
            {!isLoading1 && ` ₽`}
          </div>
          <div className="flex gap-2">
            <Button
              variant="white"
              onClick={() => isOptionsOpen >= 5 && setIsOptionsOpen(-1)}
              className="lg:!block !hidden"
            >
              Закрыть
            </Button>
            <Button
              variant={showSaveBtnSaved ? 'grey' : 'red'}
              onClick={() => handleSave()}
              disabled={!isDirty}
            >
              {showSaveBtnSaved ? 'Изменения сохранены!' : 'Сохранить'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
