import { Button, Form } from 'antd';

import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import React, { useEffect, useState } from 'react';

import axios from 'axios';

import clsx from 'clsx';

import { useQuery } from 'react-query';

import ButtonO from '../Button';
import { Divider } from '../Divider';
// import { handleGoBack } from '../Header';
import { Input } from '../Input';
import { Modal } from '../Modal';
import { CaretDownFill } from '../Icons/CaretDownFill';
import { LogoSmall } from '../Icons/LogoSmall';
import { RehauLogo } from '../Icons/RehauLogo';
import { RehauQR } from '../Icons/RehauQR';
import { TelegramIcon, VKIcon, YaIcon } from '../Icons/Socials';
import { TelephoneFill } from '../Icons/TelephoneFill';
import { PUBLIC_KEY, TEL, TELE, extUrl } from '../../utils/consts';
import { fetchFooter } from '../../utils/httpServices/global';

import { menuItems } from './menuItems';
import { getYandexMetrikaClientId, sendEventToGTM } from '../../utils/helpers';
import Cookies from 'js-cookie';

export const linkHoverStyle = 'transition-all duration-[250] hover:translate-y-[-2px]';
const inputStyle =
  'w-full !bg-[#f4f4f4] p-[5px] rounded border-[transparent] focus:border-[transparent] active:border-[transparent] focus-within:border-[transparent] hover:border-[transparent] font-SS3 font-normal';

export default function Footer() {
  const { data: footer } = useQuery('footer', fetchFooter, { staleTime: 60000 * 99 });

  const footerLS = localStorage.getItem('footer');
  const footerPLS = footerLS && JSON.parse(footerLS);
  const fdata = footer || footerPLS;

  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalFail, setIsShowModalFail] = useState(false);
  const [isShowModalOk, setIsShowModalOk] = useState(false);
  const [collapse, setCollapse] = useState(Array(menuItems.length).fill(true));
  const [token, setToken] = useState('');
  const [ip, setIp] = useState('');

  const verifyRecaptchaCallback = React.useCallback((token: string) => setToken(token), []);

  const handleCloseFail = () => {
    setIsShowModalFail(false);
    setIsShowModal(true);
  };

  const handleCloseOk = () => {
    setIsShowModalOk(false);
    setIsShowModal(false);
    // handleGoBack();
  };

  const openModalFail = () => {
    setIsShowModalOk(false);
    setIsShowModal(false);
    setIsShowModalFail(true);
  };

  const onFinish = async (values: any) => {
    const clientID = await getYandexMetrikaClientId(1145280);
    const data: any = {
      form_id: 'request-form-call',
      referer: window.location.href,
      token,
      ip,
      client_id: clientID || 'test',
      session_id: Cookies.get('_ct_session_id') || '',
      call_value: Cookies.get('_ct_session_id') || '',
      ...values,
    };
    const utmSS = sessionStorage.getItem('utm');
    if (utmSS) {
      const utm = JSON.parse(utmSS);
      if (utm._ct_session_id) {
        // _ga
        // _ym_uid
        // _ct_session_id;
        // data.clientID = utm._ym_uid || utm._ga || utm._ct_session_id;
        data.session_id = utm._ct_session_id;
      }
      if (utm.utm_source) data.utm_source = utm.utm_source;
      if (utm.utm_campaign) data.utm_campaign = utm.utm_campaign;
      if (utm.utm_medium) data.utm_medium = utm.utm_medium;
      if (utm.utm_content) data.utm_content = utm.utm_content;
      if (utm.utm_term) data.utm_term = utm.utm_term;
    } else {
      const sourceData = window.sbjs?.get?.current;
      data.utm_source = sourceData?.src;
      data.utm_campaign = sourceData?.cmp;
      data.utm_medium = sourceData?.mdm;
      data.utm_content = sourceData?.cnt;
      data.utm_term = sourceData?.trm;
    }

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    axios
      .post('https://api.mosokna.ru/api20/pages/sendForm', formData)
      .then((response) => {
        if (response?.status === 200) {
          sendEventToGTM('Callback');
          setIsShowModal(false);
          setIsShowModalOk(true);
        } else {
          openModalFail();
        }
      })
      .catch((error) => {
        console.error('error', error);
        openModalFail();
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    openModalFail();
  };

  useEffect(() => {
    const handleResize = () => {
      const isMd = window.innerWidth >= 768;
      setCollapse(Array(menuItems.length).fill(!isMd));
    };

    const fetchIp = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        if (response?.data?.ip) {
          setIp(response.data.ip);
        }
      } catch (error) {
        console.error('Error fetching the IP address:', error);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    fetchIp();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // if (footer) console.log('footer', footer);
    // if (footerPLS) console.log('footerPLS', footerPLS);

    localStorage.removeItem('footer');

    if (footer && !footer?.error) {
      localStorage.setItem('footer', JSON.stringify(footer));
    }
  }, [footer]);

  return (
    <>
      <footer className="md:px-8 px-4 md:py-[60px] py-8 bg-[#151515] text-white">
        <button
          className={clsx(
            'flex gap-2 justify-center items-center md:w-[245px] w-full px-6 py-4 bg-white text-black rounded text-sm font-semibold mb-8',
            linkHoverStyle,
          )}
          onClick={() => setIsShowModal(true)}
          onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && setIsShowModal(true)}
        >
          <TelephoneFill />
          Обратный звонок
        </button>

        <div className={`flex md:gap-10 gap-8 max-md:flex-col`}>
          {(footer?.mainmenu || menuItems).map((item: any, i: number) => (
            <div key={item.nameItem} className="md:w-fit w-full">
              <div className="font-semibold md:mb-4 flex max-md:w-full max-md:justify-between">
                {item.nameItem}

                <CaretDownFill
                  className={clsx(
                    'md:hidden transition-all',
                    !collapse[i] ? 'rotate-180' : 'rotate-0',
                  )}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setCollapse((prev) => prev.map((item, idx) => (idx === i ? !item : item)));
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setCollapse((prev) => prev.map((item, idx) => (idx === i ? !item : item)));
                    }
                  }}
                />
              </div>
              {!collapse[i] && (
                <ul className="flex flex-col gap-2 text-[15px] max-md:mt-4 max-md:pl-4">
                  {item.children.map((el: any) => (
                    <li key={el.nameItem} className={linkHoverStyle}>
                      <a href={el.link.includes('http') ? el.link : extUrl + el.link}>
                        {el.nameItem}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          <div className="flex flex-col gap-4 font-semibold">
            <div className="flex gap-4">
              <a
                href={
                  footer?.secondMenu?.[0]?.link
                    ? extUrl + footer?.secondMenu?.[0]?.link
                    : 'https://new.mosokna.ru/o-kompanii/sertifikaty'
                }
                className={linkHoverStyle}
              >
                Сертификаты
              </a>
              <a
                href={
                  footer?.secondMenu?.[1]?.link
                    ? extUrl + footer?.secondMenu?.[1]?.link
                    : 'https://new.mosokna.ru/stati'
                }
                className={linkHoverStyle}
              >
                Статьи
              </a>
            </div>
            <a
              href={
                footer?.secondMenu?.[2]?.link
                  ? extUrl + footer?.secondMenu?.[2]?.link
                  : 'https://new.mosokna.ru/privacy-policy'
              }
              className={clsx(linkHoverStyle, 'md:mb-[60px] mb-8')}
            >
              Политика конфидециальности
            </a>
            <div className="font-normal">
              <div className="text-[15px] mb-2">Мы в соц сетях</div>
              <div className="flex items-center gap-2">
                <a
                  href={
                    fdata?.socBtns?.find((el: any) => el.nameItem === 'VK')?.link ||
                    'https://vk.com/mosokna_ru'
                  }
                  className={linkHoverStyle}
                >
                  <VKIcon />
                </a>
                <a
                  href={
                    fdata?.socBtns?.find((el: any) => el.nameItem === 'DZEN')?.link ||
                    'https://dzen.ru/mosoknaru'
                  }
                  className={linkHoverStyle}
                >
                  <YaIcon />
                </a>
                <a
                  href={
                    fdata?.socBtns?.find((el: any) => el.nameItem === 'TG')?.link ||
                    'https://t.me/MOSOKNAru'
                  }
                  className={linkHoverStyle}
                >
                  <TelegramIcon />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="md:my-[60px] my-8">
          <p className="font-semibold mb-4">Наши награды</p>
          <ul className="md:flex flex-wrap items-center md:gap-[30px] max-md:gap-y-8 max-md:grid max-md:grid-cols-4">
            {(fdata?.awards || Array(8).fill(0)).map((el: any, i: number) => (
              <li
                key={el?.id || i}
                // className="md:w-[79px] w-[66px] aspect-[4/3] bg-contain bg-no-repeat bg-center hover:md:w-[200px] hover:w-[100px] transition-all hover:cursor-none"
                className="md:w-[79px] w-[66px] aspect-[4/3] bg-contain bg-no-repeat bg-center"
                style={{
                  backgroundImage: `url(${el?.img?.imgLink || `/images/medals/${i + 1}.webp`})`,
                }}
              />
            ))}
          </ul>
        </div>

        <div>
          <p className="text-[13px] max-md:mb-8">
            {fdata?.leftBlock?.descr1 ||
              'Все цены на сайте указаны с учетом действующих скидок и акций. Сайт не является публичной офертой и носит информационный характер.'}
          </p>
          <div className="flex justify-between w-full">
            <div className="flex gap-6 items-end">
              <LogoSmall />
              <div>
                <div className="md:text-sm text-[13px] font-semibold">
                  {fdata?.leftBlock?.descr2 ||
                    `© 1992—{new Date().getFullYear()} «Московские Окна»`}
                </div>
                <div className="md:text-[13px] text-[12px]">
                  {fdata?.leftBlock?.descr3 || (
                    <>
                      Права официального использования слова «Москва» письменно предоставлены
                      <br />
                      Межведомственной комиссией Правительства Москвы.
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="md:flex hidden gap-2">
              <div className="flex flex-col justify-between">
                <RehauLogo />
                <div className="text-[13px] max-w-[230px]">
                  {fdata?.rightBlock?.descr ||
                    'ООО «Московские окна» является сертифицированным производителем компании REHAU'}
                </div>
              </div>
              <a
                href={`${extUrl}${fdata?.rightBlock?.qrCodeLink || '/o-kompanii/sertifikat-rehau'}`}
              >
                <RehauQR />
              </a>
            </div>
          </div>
          <div className="md:hidden flex gap-2 mt-10">
            <div className="flex flex-col justify-between gap-2">
              <RehauLogo />
              <p className="md:text-[13px] text-[12px]">
                {fdata?.rightBlock?.descr ||
                  'ООО «Московские окна» является сертифицированным производителем компании REHAU'}
              </p>
            </div>
          </div>
        </div>
      </footer>
      {isShowModal && (
        <GoogleReCaptchaProvider reCaptchaKey={PUBLIC_KEY}>
          <Modal onClose={() => setIsShowModal(false)} className="md:w-[520px] md:!p-6">
            <h3 className="font-Istok text-[21px] font-semibold mb-1">Заказать звонок</h3>
            <p className="text-[15px]">
              Нет времени или возможности позвонить? Оставьте свой номер телефона и наш менеджер
              свяжется с вами в течение 15 минут.
              <br />
              Поля, отмеченные *, обязательны для заполнения.
            </p>

            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="my-6"
            >
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: 'Обязательное поле  ' },
                  { min: 2, message: 'Слишком короткое имя!' },
                  { max: 50, message: 'Слишком длинное имя!' },
                ]}
              >
                <Input placeholder="* Ваше имя" className={inputStyle} noBorder />
              </Form.Item>

              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: 'Обязательное поле' },
                  {
                    pattern: /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/,
                    message: 'Неверный формат номера',
                  },
                ]}
              >
                <Input
                  type="tel"
                  placeholder="* Телефон"
                  aria-label="Ваш телефон"
                  className={inputStyle}
                  noBorder
                />
              </Form.Item>

              <p className="text-sm text-center text-[#b4b4b4]">
                Нажимая на кнопку «Заказать звонок», Вы принимаете условия{' '}
                <a
                  className="hover:text-[#ff4545] transition-all"
                  href="https://www.mosokna.ru/upload/privacy-policy.pdf"
                >
                  политики конфиденциальности
                </a>{' '}
                в отношении обработки персональных данных
              </p>

              <Form.Item>
                <Button
                  className={clsx(
                    'rounded-lg flex justify-center items-center transition-all select-none',
                    '!bg-[#FF4545] !text-white disabled:!bg-[#FFC8C8] font-Istok lg:text-base text-sm',
                    'w-3/4 h-[50px] mt-6 mx-auto hover:!border-[#FF4545]',
                    linkHoverStyle,
                  )}
                  htmlType="submit"
                >
                  Заказать звонок
                </Button>
              </Form.Item>

              <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />
            </Form>

            <p className="text-sm text-[#b4b4b4]">
              Защита от спама reCAPTCHA{' '}
              <a
                className="hover:text-[#ff4545] transition-all"
                href="https://policies.google.com/privacy"
              >
                Конфиденциальность
              </a>{' '}
              и{' '}
              <a
                className="hover:text-[#ff4545] transition-all"
                href="https://policies.google.com/terms"
              >
                Условия использования
              </a>
            </p>
            <Divider className="my-6" />
            <div className="flex gap-2">
              <TelephoneFill />
              <p className="font-Istok">
                Или обратитесь к нам по телефону:{' '}
                <a className="hover:text-[#ff4545] transition-all whitespace-nowrap" href={TEL}>
                  {TELE}
                </a>{' '}
                (Москва)
              </p>
            </div>
          </Modal>
        </GoogleReCaptchaProvider>
      )}
      {isShowModalFail && (
        <Modal onClose={handleCloseFail} className="max-w-[375px] !p-[48px]">
          <h3 className="text-[18px] font-semibold">Ошибка</h3>
          <p className="mt-2 mb-6">
            При отправке сообщения произошел сбой. Попробуйте создать заявку повторно.
          </p>
          <ButtonO
            variant="red"
            className="w-full"
            onClick={handleCloseFail}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleCloseFail();
              }
            }}
          >
            Повторить
          </ButtonO>
        </Modal>
      )}
      {isShowModalOk && (
        <Modal onClose={handleCloseOk} className="max-w-[375px] !p-[48px]">
          <h3 className="text-[18px] font-semibold">Спасибо за заявку!</h3>
          <p className="mt-2 mb-6">Наш менеджер свяжется с вами в ближайшее время</p>
          <ButtonO
            variant="red"
            className="w-full"
            onClick={handleCloseOk}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleCloseOk();
              }
            }}
          >
            Закрыть
          </ButtonO>
        </Modal>
      )}
    </>
  );
}
