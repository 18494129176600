import 'react-toastify/dist/ReactToastify.css';
import './styles/main.scss';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import React, { useEffect } from 'react';

import Layout from './components/Layout';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { getToken } from './utils/httpServices/token';
import routes from './AppRoutes';
import store from './redux/store';
import useCalltouch from './utils/hooks/useCalltouch';

export default function App() {
  const queryClient = new QueryClient();
  useCalltouch();

  useEffect(() => {
    getToken();

    setTimeout(() => {
      const sourceData = window.sbjs?.get?.current; // текущие данные о визите
      const campaignData = window.sbjs?.get?.campaign; // данные о кампании

      console.log('sourceData', sourceData);
      console.log('campaignData', campaignData);
    }, 999);
  }, []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Layout>
          <BrowserRouter>
            <Routes>
              {routes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}
            </Routes>
          </BrowserRouter>
          <ToastContainer
            limit={1}
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Layout>
      </QueryClientProvider>
    </Provider>
  );
}
