import axios from 'axios';

import { URL_API } from '../consts';

export const fetchToken = async () => {
  try {
    const response = await axios.get(`${URL_API}/session`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getToken = async () => {
  try {
    const res = await fetchToken();
    if (res?.token) {
      sessionStorage.setItem('token', JSON.stringify(res));
      return res;
    }
    return '';
  } catch (error) {
    console.log(error);
  }
};

// Функция для проверки и обновления токена
export const checkAndRefreshToken = async () => {
  const tokenDataSS = sessionStorage.getItem('token');
  const tokenData = tokenDataSS ? JSON.parse(tokenDataSS) : '';
  if (tokenData && new Date(tokenData.expiration) < new Date()) {
    try {
      console.warn('Токен истек, обновляем...');
      sessionStorage.setItem('token', '');
      await getToken();
    } catch (error) {
      console.error('Ошибка получения нового токена:', error);
    }
  }
};
