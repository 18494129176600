import React from 'react';

export interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const CursorFill = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M14.0815 2.18207C14.2278 2.32836 14.2689 2.54963 14.1848 2.73869L8.52798 15.4666C8.44724 15.6483 8.26654 15.7649 8.06774 15.7635C7.86894 15.7622 7.68981 15.6432 7.6115 15.4605L5.56896 10.6946L0.803045 8.65205C0.620315 8.57374 0.501341 8.39461 0.500016 8.19581C0.49869 7.99701 0.615267 7.81631 0.796935 7.73557L13.5249 2.07871C13.7139 1.99469 13.9352 2.03578 14.0815 2.18207Z"
      fill="#B4B4B4"
    />
  </svg>
);
